import React from 'react';
import {Link, Outlet} from 'react-router-dom';

// Data
import {foodLinkData} from './Data/foodLinkData.js';

// Components


export const FoodLink = () =>
{

  return (
    <>
     {foodLinkData.map((link, index) =>
      {
        return (
          <article className="food-link" key={index}>
            <img className='food-link-img' src={link.img} alt="" />
            
            <div className="food-link-info">
              <span className="food-link-title">{link.name}</span>
              <p className="food-link-desc">
                {link.desc}
              </p>
            </div>

            <Link to={{pathname: link.path, search: link.search}}>  
              <button className="services-btn">Explore</button>        
            </Link>
          </article>
        )
      })}

    <Outlet/>
    </>
   
  )
}