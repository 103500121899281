import React from 'react';
import {Routes, Route} from 'react-router-dom';

// Data
import {hikingLinks} from './../Data/hikeLinkData.js';

//Components
import {HikingPage} from './../HikesPage.js';
import {Hike} from './../Hike.js';

export const HikingRoutes = () =>
{

  return (
    <Routes>
      <Route index element={<HikingPage/>}/>

      {hikingLinks.map((item, index) => 
      {
        return <Route path={`${item.path}`} element={<Hike/>} key={index}/>
      })}

    </Routes>
  )
}