import React from 'react'
import {Navbar} from './../Navbar.js';


export const Gear = () =>
{

  return (
    <section className="gear-section">
      <Navbar />
      <h1>Gear</h1>
    </section>
  )
}