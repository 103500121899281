import React, {useEffect} from 'react';
import {Navbar} from './../Navbar.js';
import {Footer} from './../Footer.js';
import {useGlobalContext} from '../AppContext.js';
import {displayDropdown} from '../Data/functions.js';


export const About = () =>
{
  const globalVals = useGlobalContext();
  const setShowLinks = globalVals.setShowLinks;
  const dropdownRef = globalVals.dropdownRef;

  useEffect(() =>
  {
    displayDropdown(dropdownRef, setShowLinks);
  }, [dropdownRef, setShowLinks]);

  return (
    <>
      <Navbar/>
      <section className="about-section">
        <div className="about-intro">
          <img className='about-main-img' src="/photos/About/about_me.jpg" alt="" />

          <div className="about-info-div">
            <span className="about-info-title">About Me</span>

            <p className="about-intro-desc">
              

              <span>Hi, I’m Pablo Cortez. Growing up in California I always loved traveling and exploring this beautiful state, and eventually graduated UC San Diego with a B.S. in Applied Math – Computer Science. In the Summer of 2022, I traveled to Missouri, Utah, Idaho, and Wyoming. At the end of the summer I was very fortunate to have the opportunity to live in Greece for three months. </span>
              <br></br>
              <span>
                <br/> Living in Greece was a life changing experience. I didn't know it at the time, but it changed what I wanted out of life. It wasn’t until I returned to the United State and reflected on my experience did I understand how profound this new change was. A couple things that I can share with you are <br/><br/> <b>1)</b> I want traveling to be a bigger part of my life <br/> <b>2)</b> traveling is good for the human spirit <br/><b>3)</b> I want to share and help others through my traveling experiences.
              </span>

            </p>

            
          </div>
        </div>
      </section>

      <Footer/>
    </>
  )
}