// {
//   name: '',  img:'', 
//   difficulty: 'easy', 
//   intro: ``,
//   generalInfo: 
//     [
//       {title: 'Parking', text: 'There is a parking lot, it is small and limited. Once full, there is no overflow area and it is possible you may not be allowed into the parking lot until another car leaves.'},
//       {title: 'bathrooms', text: 'Bathrooms are located by the parking lot'},
//       {title: 'Water', text: 'Drinking fountains located by the parking lot'},
//       {title: '', text: ''},
      
//     ],
//   webLinks: [],
//   elements: 
//   [
//       {title: '', img: '', paragraph: ``},
//       {title: '', img: '', paragraph: ``},
//       {title: '', img: '', paragraph: ``},
//   ],
// },




export const hikingData = [
  {
    name: 'Tennessee Valley Trail',  img:'/photos/Hikes/Tennessee/main.jpg', 
    difficulty: 'easy', 
    generalInfo: 
      [
        {title: 'Parking', text: 'Free. Paved parking lot at trailhead'},
        {title: 'Bathrooms', text: 'There are bathrooms, but they do not have running water'},
        {title: 'Water', text: 'There is no water source located on the trail'},
        {title: 'Food', text: `The grocery store 'Good Earth' is located in town close to the trail`},
        {title: 'Fees', text: `Free. There are no enterance or parking fees`},
      ],
    webLinks: [],
    elements: 
    [
        {title: 'The Beach, Cliffs, and Army Bunkers', img: '/photos/Hikes/Tennessee/image_1.jpg', paragraph: `What makes Tennessee Valley Trail so great is that it offers access to many other destinations. Once on the trail you can choose between Tennessee Valley Beach,  Muir Beach, Rodeo Beach, or explore Hill 88. If you have a limited amount of time, I'd recommend hiking to Tennessee Valley Beach. If you have half a day, Muir Beach is a great choice. If you have a full day to hike, then I'd recommend exploring Hill 88 and hiking down to Rodeo Beach. `},
        {title: 'Tourist from Abroad', img: '/photos/Hikes/Tennessee/image_2.jpg', paragraph: `If you're new to the U.S. and looking to hike, Tennessee Valley beach trail is a great trail to explore as it is well marked, easy to navigate, and is well trafficked. As long as you stay on the Tennessee Valley trail, the terrain is easy to navigate. It is common to see tourists on the weekends in jeans and sneakers. If you're unsure of your skill level, Good Earth market is located nearby where you can bring food and water.`},
        {title: 'Weather', img: '/photos/Hikes/Tennessee/image_3.jpg', paragraph: `Located on the Northern California Coast, the Tennessee Valley trail lies inside of the fog belt, which is particularly prevalent during the summer. Many people have been caught off guard because inland temperatures can be significantly coastal temperatures. During the summer It is common to see people coming from another Bay Area city or town where it is 90 degrees (32 celsius) only to be met with 60 degrees weather (15 celsius) with fog and wind.`},
    ],
    intro: `This is a perfect family friendly hike that'll lead you from the trailhead to a beautiful cove known as Tennessee Valley Beach, the distance from the trailhead to the beach is about 1.7 miles. What makes this hike so amazing is that there are trails for almost any skill level, so you can pick which is best for you. What I love the most about this trail is that it is a perfect spur of the moment trail that can be done last minute with very little need to prepare. Water, good walking shoes, and a jacket are almost all you need for this hike. If you decide to take some of the more difficult trails, then you'll definitely want to come better prepared.`,
  },



  {
    name: 'Tomales Elk Reserve',  img:'/photos/Hikes/TomalesElk/main.jpg', 
    difficulty: 'moderate', 
    intro: `This peninsula is a unique hiking experience. With beautiful cliffs, views of the Pacific Ocean, and the presence of Tule Elk, it's a hike that you won't easily forget. The trail has a round-trip length of 9.4 miles (15.12 km). Besides the length and no major elevation changes, it is easy for people of all ages to complete.`,
    generalInfo: 
      [
        {title: 'Parking', text: `It has a parking lot with 20 spots. Parking along the road is available, but observe 'No Parking' signs`},
        {title: 'Bathrooms', text: 'Availble at Mcclure beach parking lot'},
        {title: 'Food', text: 'Closest food will be in the town of Inverness'},
        {title: 'Water', text: 'There is no drinking water available at Tomales Point'},
        {title: 'Fees', text: 'Parking is free and no entrance fee'},
      ],

    webLinks: [],
    elements: 
    [
        {title: 'Tule Elk', img: '/photos/Hikes/TomalesElk/image_1.jpg', paragraph: `The reason why I highly recommend this hike is because of what you get to see given the difficulty of the hike. As the name suggests, this hike provides you with a rare opportunity to see Elk in the wild. For those of you who aren't avid hunters or wildlife photographers this could be a once in a lifetime experience.While there, I was able to spot many Elk with my binoculars and had the chance to see some that were in close proximity to the trail. Normally, if one wanted to photograph Elk, this would require driving to a remote location and hiking for hours trying to spot them.\n\nIt is important to remember that these are wild animals and can be very dangerous. During the rut season, aka breeding season, bull elk can become very aggressive as they fight for breeding rights. The rut season runs August through October. Another dangerous time is calving season when cow elk give birth, which runs from late May to early/mid June. During this time the cow elk will become protective over their calves. No matter the season, it is important that you give these elk space, as they are big and powerful animals.`},
        {title: 'Weather', img: '/photos/Hikes/TomalesElk/image_2.jpg', paragraph: `With its proximity to the Pacific Ocean, the area can experience strong winds and cold temperatures anytime of the year. Since it lies in the fog belt, fog can be present even during the summer months. Once on the trail, there is no source of shade, so make sure to bring water and sunscreen. The best way to prepare is to come dressed in layers.`},
        {title: 'Tourist from Abroad', img: '/photos/Hikes/TomalesElk/image_3.jpg', paragraph: `If you're new to the U.S. and looking to hike, Tennessee Valley beach trail is a great trail to explore as it is well marked, easy to navigate, and is well trafficked. As long as you stay on the Tennessee Valley trail, the terrain is easy to navigate. It is common to see tourists on the weekends on the Tennessee Valley trail in jeans and sneakers. If you're unsure of your skill level, Good Earth market is located nearby where you can bring food and water.`},
    ],
  },



  {
    name: 'Five Brooks Trailhead',  img:'/photos/Hikes/FiveBrooks/main.jpg', 
    difficulty: 'easy', 
    intro: `Five Brooks Trailhead to Wildcat Camp is considered to be a difficult hike that will be a challenge for a good number of hikers.This trail is about 13.4 miles (21.6km) round trip with 2,175 gain in elevation. This hike starts in Five Brooks, takes up and over the Inverness Ridge, and then down to the Wildcat Beach campsite. This may not be the best hike for inexperienced hikers.`,
    generalInfo: 
      [
        {title: 'Parking', text: 'Parking available at the trailhead'},
        {title: 'Bathrooms', text: 'Bathrooms are located by the parking lot and Wildcat campsite'},
        {title: 'Water', text: 'Water available at Wildcat campsite, no water available on the trail'},
        {title: 'Food', text: 'Closes food will be in the town of Olema'},
        {title: 'Fees', text: 'Parking is free and there are no entrance fees'},
        {title: 'Dogs', text: 'No dogs allowed on this trail'},

      ],
    webLinks: [],
    elements: 
    [
        {title: 'Hike to Wildcat Beach', img: '/photos/Hikes/FiveBrooks/main.jpg', paragraph: `The trailhead will start near the Five Brooks Ranch, where it'll be a gradual incline over the Inverness Ridge. As you go up and over the ridge, there'll be a good amount of shade from the tree canopy. The trail will end at the Wildcat beach, where you'll have access to bathrooms and water at the campsite. Since you'll be hiking to the coast it's best that you bring layers to keep you warm from the strong winds.`},
        {title: 'Come Prepared', img: '/photos/Hikes/FiveBrooks/image_2.jpg', paragraph: `This hike is located in a remote area and will have limited cell phone reception. It is important that you bring enough food and water to complete the hike, as the closest food source will be in the town of Olema. It is also important to evaluate weather conditions before you begin this hike. On cold and foggy days, this can be a pleasant hike. On extremely hot days, heat stress and heat stroke can pose a serious danger. `},
        {title: 'Wildcat Beach', img: '/photos/Hikes/FiveBrooks/image_3.jpg', paragraph: `Overall, this is a wonderful hike that I'd highly recommend for those who are ready for such a hike. Going over the ridge and ending up at the beach is such an amazing experience that's well worth the effort. As you hike over the ridge it'll be filled with beautiful sights, sounds, and the amazing smell of the forest. Since you'll reach Wildcat beach, I'd highly recommend you allocate time to explore the beach. The beach is 2.9 miles long (4.66 km), and if you travel south you'll be able to reach Alamere Falls.`},
    ],
  },

  {
    name: 'Rodeo Beach',  img:'/photos/Hikes/RodeoBeach/intro.png', 
    difficulty: 'easy', 
    generalInfo: 
      [
        {title: 'Parking', text: 'There is a parking lot and designated parking along the road'},
        {title: 'Bathrooms', text: 'There are bathrooms, but they do not have running water'},
        {title: 'Water', text: 'Water Fountain Located in Parking Lot'},
        {title: 'Food', text: 'Closest food will be in town, Sausalito'},
        {title: 'Fees', text: 'There are no parking or entrance fees'},
        {title: 'Gear', text: 'Make sure to bring warm layers as there are can be high winds any time of the year'}
      ],
    webLinks: [],
    elements: 
    [
        {title: 'Beach', img: '/photos/Hikes/RodeoBeach/main.jpg', paragraph: `Protected by cliffs, Rodeo Beach is a wonderful place to spend a beach day with the family. With a beautiful lagoon in the back, cliffs overlooking the Pacific Ocean, and colorful gems decorating the beach, many families choose this beach as a perfect picnic area. While some picnic on the beach there are picnic tables by the lagoon where you can watch the birds. Many also bring their dogs with them as the beach does allow dogs to be off leash.`},
        {title: 'Weather', img: '/photos/Hikes/RodeoBeach/image_2.jpg', paragraph: `Located on the Northern California Coast, the Tennessee Valley trail lies inside of the fog belt, which is particularly prevalent during the summer. Many people have been caught off guard because inland temperatures can be significantly coastal temperatures. During the summer It is common to see people coming from another Bay Area city or town where it is 90 degrees (32 celsius) only to be met with 60 degrees weather (15 celsius) with fog and wind.`},
        {title: 'The Coastline', img:  '/photos/Hikes/RodeoBeach/image_3.jpg', paragraph: `If you hike around rodeo beach you'll soon realize that you can explore the cliffs, but  I don't recommend them for everyone. It is very easy to find yourself in danger, as the waves, wind, and rough terrain can all add up to create a really volatile situation. The worst part is that you can be caught by Sneaker Waves, which are unpredictable and more powerful than you can imagine. An unfortunate fact is that many have died in the Bay Area due to Sneaker waves. If you have young children, stick to the beach, and stay away from the cliffs. What people also don't realize is after being exposed to the cold water and high winds you can be in danger of hypothermia. Once wet, your clothes and the wind will rob you of your body heat, and depending how far you're away from your car you can find yourself in trouble. What I hope people will also understand is that hypothermia can happen all year round in Northern California, even during the summer.  `},
        {title: 'Army Bunkers', img: '', paragraph: `As a former military site for the United States Army the surrounding areas are littered with abandoned army bunkers. Some of these have been well preserved and still accessible to the public. Due to their strategic locations, many of them will have stunning views overlooking the pacific ocean. Many of them are easily reachable with well maintained paths leading up to them. `},
        {title: 'Nike Missile Site', img: '/photos/Hikes/RodeoBeach/image_5.jpg', paragraph: `Currently Open, Thursdays, Fridays, and Saturdays: 12:00pm-3:00pm. On the first Saturday of the Month veterans of the Nike program are on site to share their stories. \n\nThis is an incredible experience to see first hand a piece of history that a small number of civilians will ever get to see. As a relic of the Cold War, it's an eye opening experience to learn how serious tensions between the United States and the Soviets became. For those of you who are lucky enough to come on the first Saturday of the month, talking to and hearing the stories of the Veterans who operated the site is a truly profound experience. `},
        {title: 'Tourist from Abroad', img: '/photos/Hikes/RodeoBeach/image_1.jpg', paragraph: `For those coming abroad, Rodeo beach is an excellent choice to add to your itinerary. Once you park you can quickly make your way to the beach or hike along the coastline. If you come from a landlocked country you should make sure to take the Tennessee Point Trail which will give you access to some of the most stunning views of the coastline.The area and trails are well marked, making navigating it relatively easy. The area can get windy and cold no matter the time of the year, so be ready to experience sweater weather. `},
    ],
    intro: `While Rodeo beach itself is absolutely spectacular to visit, it and the surrounding areas are an absolute treasure to the world that we must all be thankful to have been preserved for us and future generations. As part of the Golden Gate National Recreation Area, it is part of 82,116 acres of protected land. Thanks to this distinction, we and future generations have been gifted a time capsule to explore. Once there, you can explore the beach, the coastline, abandoned army bunkers, and even the restored Nike Missile Site.`,
  },

  {
    name: 'Hill 88 via Wolf Ridge',  img:'/photos/Hikes/WolfRidge/main.jpg', 
    difficulty: 'easy', 
    intro: `For those who find themselves in the Bay Area, Wolf Ridge Trail is a must hike. Surrounded by fog and old army bunkers, this is a hidden gem that leads you to a former Cold War era radar station. Nestled in the Marin Headlands, this one of several locations you can explore. `,
    generalInfo: 
      [
        {title: 'Parking', text: 'There is a parking lot and designated parking along the road'},
        {title: 'Bathrooms', text: 'There are bathrooms, but they do not have running water'},
        {title: 'Water', text: 'Water Fountain Located in Parking Lot'},
        {title: 'Food', text: 'Closest food will be in town, Sausalito'},
        {title: 'Fees', text: 'There are no parking or entrance fees'},
        {title: 'Gear', text: 'Make sure to bring warm layers as there are can be high winds any time of the year'}
      ],
    webLinks: [],
    elements: 
    [
        {title: 'Wolf Ridge Trail', img: '/photos/Hikes/WolfRidge/image_1.jpg', paragraph: `There are many ways to get to Wolf Ridge Tail, which can be accessed by Rodeo Beach or Tennessee Valley. If you choose Rodeo Beach, you can start at the parking lot and either access it by the Coastal Trail or the Miwok Trail. If you decide Tennessee Valley, from the parking lot you'll take the Tennessee Valley Trail and then take the Old Spring Trail.`},
        {title: `Army Bunkers`, img: `/photos/Hikes/WolfRidge/image_2.jpg`, paragraph: `Scattered all over the hills are Army bunkers from WWII and the Cold War. Some, like Battery Townsley have been preserved by the National Parks, while others can be found by taking unmarked trails.`},
        {title: 'Hill 88', img: '/photos/Hikes/WolfRidge/image_3.jpg', paragraph: `Hill 88 is a former Cold War era radar station that was a part of the Nike Missile site. The hill is now home to many dilapidated buildings and stunning views of the San Francisco Bay and Golden Gate Bridge. This is a great place to visit as it's a rare combination of hiking and urban exploration.`},
        // {title: `Graffiti`, img: ``, paragraph: ``}
        // {title: 'The Coastline', img:  '/photos/Hikes/RodeoBeach/image_3.jpg', paragraph: `If you hike around rodeo beach you'll soon realize that you can explore the cliffs, but  I don't recommend them for everyone. It is very easy to find yourself in danger, as the waves, wind, and rough terrain can all add up to create a really volatile situation. The worst part is that you can be caught by Sneaker Waves, which are unpredictable and more powerful than you can imagine. An unfortunate fact is that many have died in the Bay Area due to Sneaker waves. If you have young children, stick to the beach, and stay away from the cliffs. What people also don't realize is after being exposed to the cold water and high winds you can be in danger of hypothermia. Once wet, your clothes and the wind will rob you of your body heat, and depending how far you're away from your car you can find yourself in trouble. What I hope people will also understand is that hypothermia can happen all year round in Northern California, even during the summer.  `},
        // {title: 'Army Bunkers', img: '', paragraph: `As a former military site for the United States Army the surrounding areas are littered with abandoned army bunkers. Some of these have been well preserved and still accessible to the public. Due to their strategic locations, many of them will have stunning views overlooking the pacific ocean. Many of them are easily reachable with well maintained paths leading up to them. `},
        // {title: 'Nike Missile Site', img: '/photos/Hikes/RodeoBeach/image_5.jpg', paragraph: `Currently Open, Thursdays, Fridays, and Saturdays: 12:00pm-3:00pm. On the first Saturday of the Month veterans of the Nike program are on site to share their stories. \n\nThis is an incredible experience to see first hand a piece of history that a small number of civilians will ever get to see. As a relic of the Cold War, it's an eye opening experience to learn how serious tensions between the United States and the Soviets became. For those of you who are lucky enough to come on the first Saturday of the month, talking to and hearing the stories of the Veterans who operated the site is a truly profound experience. `},
        // {title: 'Tourist from Abroad', img: '/photos/Hikes/RodeoBeach/image_1.jpg', paragraph: `For those coming abroad, Rodeo beach is an excellent choice to add to your itinerary. Once you park you can quickly make your way to the beach or hike along the coastline. If you come from a landlocked country you should make sure to take the Tennessee Point Trail which will give you access to some of the most stunning views of the coastline.The area and trails are well marked, making navigating it relatively easy. The area can get windy and cold no matter the time of the year, so be ready to experience sweater weather. `},
    ],
  },

  {
    name: 'SCA Trail',  img:'/photos/Hikes/SCA_Trail/main.jpg', 
    difficulty: 'easy', 
    intro: `Located in the Marin Headlands, this trail is known as an access trail to Slacker Hill, which has the highest views of the Golden Gate Bridge. This scenic viewpoint is a great place for pictures of the Golden Gate Bridge and the fog that blankets the Bay Area.`,
    generalInfo: 
      [
        {title: 'Parking', text: 'Rodeo Beach parking lot, Coastal Trail parking lot, or GG Bridge North-West parking lot'},
        {title: 'Bathrooms', text: 'Bathrooms at Rodeo Beach'},
        {title: 'Water', text: 'Water fountain at Rodeo Beach parking lot'},
        {title: 'Food', text: 'Closest food will be in town, Sausalito'},
        {title: 'Fees', text: 'There are no parking or entrance fees'},
        {title: 'Gear', text: 'Make sure to bring warm layers as there are can be high winds any time of the year'}
      ],
    webLinks: [],
    elements: 
    [
        {title: 'Trailhead and Parking', img: '/photos/Hikes/SCA_Trail/image_1.jpg', paragraph: `The trailhead is located in the Northwest parking lot of the Golden Gate Bridge, but be careful as that parking lot is a smash-and-grab hotspot. Another access point is the Coastal Trail parking lot at the intersection of Conzelman Rd and McCullough Rd, near the roundabout. \n\n The trail is about 4.02 miles long round trip, with many turning back once they reach the top of Slacker Hill. This is a great hike if you have extra time while visiting the Golden Gate Bridge.`},
        {title: `Golden Gate Brigde`, img: `/photos/Hikes/SCA_Trail/image_2.jpg`, paragraph: `This trail is popular amongst tourist visiting the Golden Gate Bridge since it leads to the Slacker Hill trail. Slacker Hill has the highest viewpoint of the Golden Gate Bridge in the Marin Headlands. Along this trail you'll also get views of the Robin Williams Tunnel, Angel Island, and San Francisco.`},
        {title: 'Weather', img: '/photos/Hikes/SCA_Trail/image_3.jpg', paragraph: `With its proximity to the San Francisco Bay, this area regularly experiences high winds and cold breezes from the Pacific. Given that it lies inside of the fog bank, you can expect for the fog to roll in mid day, especially during the summer months.`},
        // {title: `Graffiti`, img: ``, paragraph: ``}
        // {title: 'The Coastline', img:  '/photos/Hikes/RodeoBeach/image_3.jpg', paragraph: `If you hike around rodeo beach you'll soon realize that you can explore the cliffs, but  I don't recommend them for everyone. It is very easy to find yourself in danger, as the waves, wind, and rough terrain can all add up to create a really volatile situation. The worst part is that you can be caught by Sneaker Waves, which are unpredictable and more powerful than you can imagine. An unfortunate fact is that many have died in the Bay Area due to Sneaker waves. If you have young children, stick to the beach, and stay away from the cliffs. What people also don't realize is after being exposed to the cold water and high winds you can be in danger of hypothermia. Once wet, your clothes and the wind will rob you of your body heat, and depending how far you're away from your car you can find yourself in trouble. What I hope people will also understand is that hypothermia can happen all year round in Northern California, even during the summer.  `},
        // {title: 'Army Bunkers', img: '', paragraph: `As a former military site for the United States Army the surrounding areas are littered with abandoned army bunkers. Some of these have been well preserved and still accessible to the public. Due to their strategic locations, many of them will have stunning views overlooking the pacific ocean. Many of them are easily reachable with well maintained paths leading up to them. `},
        // {title: 'Nike Missile Site', img: '/photos/Hikes/RodeoBeach/image_5.jpg', paragraph: `Currently Open, Thursdays, Fridays, and Saturdays: 12:00pm-3:00pm. On the first Saturday of the Month veterans of the Nike program are on site to share their stories. \n\nThis is an incredible experience to see first hand a piece of history that a small number of civilians will ever get to see. As a relic of the Cold War, it's an eye opening experience to learn how serious tensions between the United States and the Soviets became. For those of you who are lucky enough to come on the first Saturday of the month, talking to and hearing the stories of the Veterans who operated the site is a truly profound experience. `},
        // {title: 'Tourist from Abroad', img: '/photos/Hikes/RodeoBeach/image_1.jpg', paragraph: `For those coming abroad, Rodeo beach is an excellent choice to add to your itinerary. Once you park you can quickly make your way to the beach or hike along the coastline. If you come from a landlocked country you should make sure to take the Tennessee Point Trail which will give you access to some of the most stunning views of the coastline.The area and trails are well marked, making navigating it relatively easy. The area can get windy and cold no matter the time of the year, so be ready to experience sweater weather. `},
    ],
  },


  {
    name: 'Trojan Point',  img:'/photos/Hikes/TrojanPoint/main.jpg', 
    difficulty: 'easy', 
    intro: `An absolutely stunning viewpoint, Trojan Point is scattered with many hiking trails that can easily be hiked in a short period of time. Located on Mt. Tamalpais, Trojan Point has views of the San Francisco Bay, Marin County, and Stinson Beach. `,
    generalInfo: 
      [
        {title: 'Parking', text: 'Parking lot on Pantoll Rd.'},
        {title: 'Bathrooms', text: 'No Bathrooms'},
        {title: 'Water', text: 'No Water'},
        {title: 'Food', text: 'Closest food will in Mill Valley'},
        {title: 'Fees', text: 'There are no parking or entrance fees'},
        {title: 'Gear', text: 'Keep a jacket or sweater in the car if needed'}
      ],
    webLinks: [],
    elements: 
    [
        {title: 'Parking', img: '/photos/Hikes/TrojanPoint/image_1.png', paragraph: `There is a small parking lot for Trojan Point located on Pantoll Rd. Once parked, the views start almost immediately with a short hike to reach the scenic overlook. `},
        {title: 'Scenic Overlook', img: `/photos/Hikes/TrojanPoint/image_2.jpg`, paragraph: `A wonderful feature of Trojan Point are the views more so than the trails themselves. With most opting to find a nice spot to sit and relax. The scenic overlook near the parking lot can be busy, so if you're looking for some peace and quiet, you can quickly lose people by hiking up the steeper trails across the street. `},
        {title: 'Trails', img: '/photos/Hikes/TrojanPoint/image_3.jpg', paragraph: `Even though there are some steep trails they are relatively short and can be easily completed by hikers of any skill level. If you're short on time Trojan Point doesn't require a long hike to reach the best views, with many in reach after only a couple of minutes walking.`},
        // {title: `Graffiti`, img: ``, paragraph: ``}
        // {title: 'The Coastline', img:  '/photos/Hikes/RodeoBeach/image_3.jpg', paragraph: `If you hike around rodeo beach you'll soon realize that you can explore the cliffs, but  I don't recommend them for everyone. It is very easy to find yourself in danger, as the waves, wind, and rough terrain can all add up to create a really volatile situation. The worst part is that you can be caught by Sneaker Waves, which are unpredictable and more powerful than you can imagine. An unfortunate fact is that many have died in the Bay Area due to Sneaker waves. If you have young children, stick to the beach, and stay away from the cliffs. What people also don't realize is after being exposed to the cold water and high winds you can be in danger of hypothermia. Once wet, your clothes and the wind will rob you of your body heat, and depending how far you're away from your car you can find yourself in trouble. What I hope people will also understand is that hypothermia can happen all year round in Northern California, even during the summer.  `},
        // {title: 'Army Bunkers', img: '', paragraph: `As a former military site for the United States Army the surrounding areas are littered with abandoned army bunkers. Some of these have been well preserved and still accessible to the public. Due to their strategic locations, many of them will have stunning views overlooking the pacific ocean. Many of them are easily reachable with well maintained paths leading up to them. `},
        // {title: 'Nike Missile Site', img: '/photos/Hikes/RodeoBeach/image_5.jpg', paragraph: `Currently Open, Thursdays, Fridays, and Saturdays: 12:00pm-3:00pm. On the first Saturday of the Month veterans of the Nike program are on site to share their stories. \n\nThis is an incredible experience to see first hand a piece of history that a small number of civilians will ever get to see. As a relic of the Cold War, it's an eye opening experience to learn how serious tensions between the United States and the Soviets became. For those of you who are lucky enough to come on the first Saturday of the month, talking to and hearing the stories of the Veterans who operated the site is a truly profound experience. `},
        // {title: 'Tourist from Abroad', img: '/photos/Hikes/RodeoBeach/image_1.jpg', paragraph: `For those coming abroad, Rodeo beach is an excellent choice to add to your itinerary. Once you park you can quickly make your way to the beach or hike along the coastline. If you come from a landlocked country you should make sure to take the Tennessee Point Trail which will give you access to some of the most stunning views of the coastline.The area and trails are well marked, making navigating it relatively easy. The area can get windy and cold no matter the time of the year, so be ready to experience sweater weather. `},
    ],
  },


  {
    name: 'Armstrong Redwood Forest',  img:'/photos/Hikes/ArmstrongForest/main.jpg', 
    difficulty: 'easy', 
    intro: `Armstrong State Park is a beautiful old-growth redwood forest that sits at the bottom of a canyon. Located in Sonoma County, it's a great alternative to Muir Woods as it's 251 acres larger in size.With most of the main trails flat and well kept, it's a family friendly hike for those with strollers or mobility issues. `,
    generalInfo: 
      [
        {title: 'Parking', text: 'Paid parking, no bills larger than $20'},
        {title: 'Bathrooms', text: 'Bathrooms Available'},
        {title: 'Water', text: 'Drinking Fountains'},
        {title: 'Food', text: 'Food available in town, Guerneville'},
        {title: 'Fees', text: 'No entrance fee just paid parking'},
        {title: 'Gear', text: 'Only weather appropriate clothing needed'}
      ],
    webLinks: [{title: 'Website', text: `Armstrong Website`, url: `https://www.parks.ca.gov/?page_id=450`}],
    elements: 
    [
        {title: 'Parking', img: '/photos/Hikes/ArmstrongForest/image_1.jpg', paragraph: `Parking is available inside of the forest and outside of the main gate. I'd highly recommend parking inside as the 1 mile trip to the parking lot is a beautiful drive with Redwood trees on both sides of the road. Once inside, you'll also have the opportunity to park and have lunch at the tables next to the Redwood trees.`},
        {title: 'Hiking Amongst the Redwoods', img: `/photos/Hikes/ArmstrongForest/image_2.jpg`, paragraph: `For those of you who have always wanted to hike in a Redwood forest, Armstrong State Park is a fantastic choice as it is less crowded compared to Muir Woods. While hiking there around 3:00pm in the afternoon there were times where I found myself alone from other hikers. This allowed me to enjoy many of the sights, sounds, and smells of the park alone and without disturbance from other visitors. `},
        {title: 'Coastal Redwoods', img: '/photos/Hikes/ArmstrongForest/image_3.jpg', paragraph: `The Coastal Redwoods are an absolute treasure that seem to have a profound impact on those who visit them. It seems as if everyone leaves with a newfound respect for how incredible these trees are. As an old-growth forest, the size and scale of these trees make it difficult to take pictures of them and capture how beautiful they are.`},
        {title: `The Forest Floor`, img: `/photos/Hikes/ArmstrongForest/image_4.jpg`, paragraph: `While the Redwood trees themselves are absolutely beautiful, the forest floor that they produce is a biodiversity hotspot that is absolutely amazing. As the sun cuts through the canopy, it produces magical streams of light that produce a spotlight showcasing the forest. When you focus your attention to the forest floor, you'll find an abundance of ferns, mushrooms, and Redwood-Sorrel. If you're lucky you'll be able to spot deer, squirrels, and other animals foraging in the forest. `}
        // {title: 'The Coastline', img:  '/photos/Hikes/RodeoBeach/image_3.jpg', paragraph: `If you hike around rodeo beach you'll soon realize that you can explore the cliffs, but  I don't recommend them for everyone. It is very easy to find yourself in danger, as the waves, wind, and rough terrain can all add up to create a really volatile situation. The worst part is that you can be caught by Sneaker Waves, which are unpredictable and more powerful than you can imagine. An unfortunate fact is that many have died in the Bay Area due to Sneaker waves. If you have young children, stick to the beach, and stay away from the cliffs. What people also don't realize is after being exposed to the cold water and high winds you can be in danger of hypothermia. Once wet, your clothes and the wind will rob you of your body heat, and depending how far you're away from your car you can find yourself in trouble. What I hope people will also understand is that hypothermia can happen all year round in Northern California, even during the summer.  `},
        // {title: 'Army Bunkers', img: '', paragraph: `As a former military site for the United States Army the surrounding areas are littered with abandoned army bunkers. Some of these have been well preserved and still accessible to the public. Due to their strategic locations, many of them will have stunning views overlooking the pacific ocean. Many of them are easily reachable with well maintained paths leading up to them. `},
        // {title: 'Nike Missile Site', img: '/photos/Hikes/RodeoBeach/image_5.jpg', paragraph: `Currently Open, Thursdays, Fridays, and Saturdays: 12:00pm-3:00pm. On the first Saturday of the Month veterans of the Nike program are on site to share their stories. \n\nThis is an incredible experience to see first hand a piece of history that a small number of civilians will ever get to see. As a relic of the Cold War, it's an eye opening experience to learn how serious tensions between the United States and the Soviets became. For those of you who are lucky enough to come on the first Saturday of the month, talking to and hearing the stories of the Veterans who operated the site is a truly profound experience. `},
        // {title: 'Tourist from Abroad', img: '/photos/Hikes/RodeoBeach/image_1.jpg', paragraph: `For those coming abroad, Rodeo beach is an excellent choice to add to your itinerary. Once you park you can quickly make your way to the beach or hike along the coastline. If you come from a landlocked country you should make sure to take the Tennessee Point Trail which will give you access to some of the most stunning views of the coastline.The area and trails are well marked, making navigating it relatively easy. The area can get windy and cold no matter the time of the year, so be ready to experience sweater weather. `},
    ],
  },


  {
    name: 'Sonoma Coast',  img:'/photos/Hikes/SonomaCoast/main.jpg', 
    difficulty: 'easy', 
    intro: `While many hiking trails will lead you to the beach, the Sonoma Coast State Park Kortum trail has unobstructed views of the Pacific Ocean, beaches, and cliffs for almost the entire way. This is a great family trail as it is well maintained and almost completely flat for most of the way. This trail will give you access to the coast line, beautiful bluffs, and multiple beaches`,
    generalInfo: 
      [
        {title: 'Parking', text: `Parking available along multiple points on the trail: Duncan's Landing, Wrights Beach, Shell Beach, and Goat Rock Beach`},
        {title: 'Bathrooms', text: 'Bathrooms Available at Shell Beach and Goat Rock Beach'},
        {title: 'Water', text: `Didn't see any drinking fountains while there`},
        {title: 'Food', text: 'Food available in town, Bodega Bay'},
        {title: 'Fees', text: 'No parking fees. There are camping fees'},
        {title: 'Gear', text: 'Food, water, and weather appropriate clothing'}
      ],
    webLinks: [{title: 'Website', text: `Kortum Trail Website`, url: `https://www.sonomacounty.com/outdoor-activities/kortum-trail`}],
    elements: 
    [
        {title: 'Kortum Trail', img: '/photos/Hikes/SonomaCoast/image_1.jpg', paragraph: `Stretching from Wrights Beach in the South to Blind Beach in the North, the Kortum Trail is 4.7 miles roundtrip. Towards the end of July, this was a great hike, as I had the fog on one side and the coastal breeze on the other, making it very pleasant and refreshing. The trail goes from South-to-North or North-to-South, depending where you want to start, making navigation easy and intuitive. Parking is available at Wright's Beach, Shell Beach, Goat Rock, and Goat Rock Beach, giving you great flexibility as to how much or how little of the hike you'd like to do. For those who'd like a shorter hike, you can choose to park at Shell Beach or Goat Rock Beach. For those wanting to do the full hike, you can park at either end, Wright's Beach or Goat Rock Beach.`},
        {title: 'Activities', img: `/photos/Hikes/SonomaCoast/image_2.jpg`, paragraph: `What I really liked about the Kortum trail is the fact that you can access it at different points, creating multiple ways you can enjoy the area.You can take a long hike, head to the beach, or set up a nice picnic. There was even a group who set up lawn chairs on the bluffs just to enjoy the views. This is one of my new favorite parks due to how versatile it is.`},
        {title: 'The Fog', img: '/photos/Hikes/SonomaCoast/image_3.jpg', paragraph: `If you've never encountered the summer fog in Northern California then the Kortum trail is a great way to experience it. The fog has a beautiful way of changing the landscape, it's a curtain that creates a yearning to explore what lies hidden within it. At times, the fog can be so thick that it hides everyone around giving you a wonderful sense of privacy.`},
    ],
  },

  {
    name: 'Alamere Falls',  img:'/photos/Hikes/AlamereFalls/main.jpg', 
    difficulty: 'moderate', 
    intro: `This is a stunning hike that will take you to an extremely rare "Tidefall", a waterfall that leads directly to the ocean. With only 35 tidefalls in the world, this a great opportunity to visit one in Point Reyes National Seashore. It's a 13 mile round trip hike that'll take along the coast, through the forest, and past beautiful lakes. `,
    generalInfo: 
      [
        {title: 'Parking', text: `Palomarin parking lot. Last 1.2 miles is an unpaved road.`},
        {title: 'Bathrooms', text: 'Bathrooms Available at parking lot and Wildcat Beach campground'},
        {title: 'Water', text: `Water available at Wildcat campground, 11 miles from trailhead`},
        {title: 'Food', text: 'Food available in town'},
        {title: 'Fees', text: 'No parking fees. There are camping fees'},
        {title: 'Gear', text: 'Food, water, and weather appropriate clothing'}
      ],
    webLinks: [],
    elements: 
    [
        {title: 'Alamere Falls', img: '/photos/Hikes/AlamereFalls/image_1.jpg', paragraph: `Located at the South end of Wildcat Beach, this waterfall is one of only 35 in the world that flows directly into the ocean making it a bucket list hike for anyone here in the Bay Area. The water still flows during the summer, as seen in the photos, which were taken August 3rd. You are able to see a heavier flow during the winter and spring months during the rainy season season. You can only reach the Alamere Falls by hiking, with the shortest way being a 6.5 mile hike from the Palomarin trailhead. `},
        {title: 'Palomarin Trailhead', img: `/photos/Hikes/AlamereFalls/image_2.jpg`, paragraph: `The Palomarin trailhead is a popular way to reach Alamere Falls as it is the shortest compared to Fivebrooks or Bear Valley trailheads. Once parked at the Palomarin parking lot, you'll continue to Alamere Falls via the Coastal Trail. It's a beautiful hike with views of the Pacific ocean, and where you'll hit some shaded areas as you cut through the forest. The summer months in the Bay Area are some of the foggiest, which we encountered during our hike, which was refreshing and kept us cool.`},
        {title: 'The Lakes', img: '/photos/Hikes/AlamereFalls/image_3.jpg', paragraph: `When taking the Coastal Trail you'll hike past Bass Lake, Pelican Lake, Crystal Lake, and Ocean Lake. This was a pleasant surprise. The lakes were stunning, especially with the fog flowing across them. They also made for an excellent marker for a rest stop to drink water, grab a quick bite to eat, and take some photos.`},
    ],
  },


  {
    name: 'Dias Ridge',  img:'/photos/Hikes/Dias_Ridge/main.jpg', 
    difficulty: 'easy', 
    intro: `Down the road from the world famous Muir Woods, Dias Ridge is a wonderful hiking trail that takes you from the coastline, through the fog to the top of the ridge, and around the bottom of the ridge. 1.8 miles one direction. To loop back, take Redwood Creek Trail via Miwok trail or Green Gulch Trail via Miwok Trail. `,
    generalInfo: 
      [
        {title: 'Parking', text: `Parking available at Muir Beach parking lot`},
        {title: 'Bathrooms', text: 'Bathrooms available at Muir Beach'},
        {title: 'Water', text: `Water available at Muir Beach`},
        {title: 'Food', text: 'Food available in Mill Valley'},
        {title: 'Fees', text: 'No parking fees'},
        {title: 'Gear', text: 'Food, water, and weather appropriate clothing'}
      ],
    webLinks: [],
    elements: 
    [
        {title: 'Trailhead', img: '/photos/Hikes/Dias_Ridge/image_1.jpg', paragraph: `The trailhead is a little difficult to find, as it is located across the street from the Muir Beach entrance, right behind the mailboxes as seen in the photo.`},
        {title: 'California Coastal Grassland', img: `/photos/Hikes/Dias_Ridge/image_2.jpg`, paragraph: `When you begin your hike you'll start at sea level and as you make your way up you'll venture through a California Coastal Grassland that is identifiable due its lack of trees. The hike up the ridge will also have stunning views of the Pacific Ocean and Muir Beach. `},
        {title: 'The Fog', img: '/photos/Hikes/Dias_Ridge/image_3.jpg', paragraph: `One of my favorite parts of the trail was hiking into the fog the closer you got to the top of the ridge. The fog was refreshing and kept us cool compared to other parts of the Bay Area which are much hotter during the summer months. What was also interesting was rapid change in vegetation as you crossed into the fog layer. Parts of the hike were saturated as moisture collected on the trees and plants. `},
        {title: 'Heading Back', img: '/photos/Hikes/Dias_Ridge/image_4.jpg', paragraph: `The Dias Ridge trail is a feeder trail, in order to head back you can either backtrack or take another trail that loops back. If you'd like to loop back you can take Redwood Creek trail(counter clockwise) via Miwok trail or Green Gulch trail(clockwise) via Miwok. `},
    ],
  },

  {
    name: 'Limantour Beach',  img:'/photos/Hikes/Limantour/main.jpg', 
    difficulty: 'easy', 
    intro: `Located in West Marin, Limantour Beach is one of four beaches that you can drive up to at Point Reyes National Park. It is a peninsula with windswept dunes, stunning views of the Pacific Ocean, and extraordinary wildlife to observe. `,
    generalInfo: 
      [
        {title: 'Parking', text: `Parking available near the entrance of the beach`},
        {title: 'Length', text: 'Around 2.2 miles round trip'},
        {title: 'Bathrooms', text: 'Bathrooms available at the parking lot'},
        {title: 'Food', text: 'Closest available food located at Inverness or Point Reyes Station'},
        {title: 'Fees', text: 'No parking fees'},
        {title: 'Gear', text: 'Food, water, sunscreen, and weather appropriate clothing'}
      ],
    webLinks: [],
    elements: 
    [
        {title: 'Trailhead', img: '/photos/Hikes/Limantour/image_1.jpg', paragraph: `The trailhead is conveniently located near the parking lot where you'll go down a dirt path, across a bridge, up and over a sand dune that'll lead you to the beach. `},
        {title: 'The Penninsula', img: `/photos/Hikes/Limantour/image_2.jpg`, paragraph: `As a small peninsula, this beach is an extraordinary place to visit, with one side flanked by the Pacific Ocean and the other with the marsh and cliffs. I highly recommend making the trip around the whole beach, as the far end of the beach will have amazing views where the Pacific and marsh meetup.`},
        {title: 'The Dunes', img: '/photos/Hikes/Limantour/image_3.jpg', paragraph: `As you make your way down the beach you'll be flanked by pristine sand dunes that have been shaped by the waves and the wind. With many sand dunes destroyed or damaged due to their high volume of visitors, it's always great to find a beach with dunes in great shape. Be careful not to disturb or damage them.`},
        {title: 'The Wind', img: '/photos/Hikes/Limantour/image_4.jpg', paragraph: `Limantour beach gets strong winds on a regular basis, but this should not deter you as it adds to the wonderful experience. When I was there, the winds got stronger the closer I got to the tip of the peninsula. Amazingly, when the winds got strong enough they created an illusion that made the sand look like a flowing river. `},
    ],
  },

  {
    name: 'Chimney Rock',  img:'/photos/Hikes/ChimneyRock/main.jpg', 
    difficulty: 'easy', 
    intro: `Chimney Rock trail is an easy 1.8 mile round trip hike that leads to some of the most stunning views of the Pacific Ocean. For such a short hike this trail is a spectacular experience that almost feels like heaven. From the views, to the winds, clouds, you'll feel like you've found a magical location that shouldn't exist.`,
    generalInfo: 
      [
        {title: 'Parking', text: `Parking available at the trail head. Becareful, one lane used for two way traffic`},
        {title: 'Length', text: '1.8 miles roundtrip'},
        {title: 'Bathrooms', text: 'Bathrooms available at the parking lot'},
        {title: 'Food', text: 'Closest available food located at Inverness or Point Reyes Station'},
        {title: 'Fees', text: 'No parking fees'},
        {title: 'Gear', text: 'Food, water, sunscreen, and weather appropriate clothing'}
      ],
    webLinks: [],
    elements: 
    [
        {title: 'The Views', img: '/photos/Hikes/ChimneyRock/image_1.jpg', paragraph: `This is a relatively short trail with only one small incline and no difficult obstacles. If you like taking pictures, I'd highly recommend this location as you'll be able to get great pictures of the ocean, the coastline, and Drakes Beach. Whether you go on a sunny day or not, the views will be spectacular. `},
        {title: 'The Waves', img: `/photos/Hikes/ChimneyRock/image_2.jpg`, paragraph: `For those of you who have never experienced the waves of Point Reyes, you're in for a treat. They have this raw beauty that comes from their size and power that you can feel as they come crashing down. If you venture onto North or South Beach you'll instantly understand what I'm talking about. The waves in Point Reyes are big and dangerous and highly recommended not to go near, that is why Chimney Rock trail is a great way to get another vantage point of these beautiful waves from a safe place. `},
        {title: 'The Wind', img: '/photos/Hikes/ChimneyRock/image_3.jpg', paragraph: `One aspect of hikes that makes this experience so profound is the wind that you encounter. While I was there the wind was at 31mph with gusts up to 40-45mph.That is why I highly recommend you be careful and to not even venture near the cliff. I experienced some extremely powerful gusts of winds that were pushing me completely off the path, I could only imagine what could have happened if I was near the edge of one of those cliffs.`},
        // {title: '', img: '/photos/Hikes/ChimneyRock/image_3.jpg', paragraph: ``},
        
    ],
  },
]