import React from 'react';
import {Routes, Route} from 'react-router-dom';

// Data
import {foodLinkData} from './Data/foodLinkData.js';

// Componenets
import {FoodPage} from './FoodPage.js';
import {Restaurant} from './Restaurant.js'

export const FoodRoutes = () =>
{
  return (
    <Routes>
      <Route index element={<FoodPage/>} />
      
      {foodLinkData.map((link, index) =>
      {
        return <Route path={link.path} element={<Restaurant/>} key={index}/>
      })}
    </Routes>
  )
}