import React from 'react';
import {useLocation} from 'react-router-dom';
// import {Links} from 'react-router-dom';

//Data
import {areaData} from './DataFolder/data';
import {countryGeneralInfo} from './DataFolder/countriesData';

// Components
import {Navbar} from '../Navbar';
import {Footer} from '../Footer';
import {CountryAttractions} from './CountryServices.js';

export const Country = () =>
{
  const index = (useLocation().state);
  const country = areaData[index];
  const genInfo = countryGeneralInfo[index];

 
  return (
    <>
      <Navbar/>
      {/* Filter bar will be placed here */}

      <section className="country-section" style={{backgroundImage: `url(${country.img})`}} >
        <h1 className='country-title'>{country.name}</h1>
      </section>

      <section className="country-info">
        <h3 className='country-info-title'>Country Information</h3>
        <ul className='country-info-list'>
          <li><span className='blue-text'>Language</span>: English</li>
          <li><span className='blue-text'>Currency</span>: US Dollar</li>
          <li><span className='blue-text'>Check YourVisa Info</span>: <a href={genInfo.visa}>PassportIndex.org</a></li>
          <li><span className='blue-text'>Costs</span>: <a href={genInfo.costs}>Numbeo.com</a></li>
          <li><span className='blue-text'>In-depth Info</span>: <a href={genInfo.wikitravel}>Wikitravel</a></li>
        </ul>
      </section>


      <section className="country-tidbits-section">
        <div className="country-tidbits-container">
          <div className="country-tidibits-inner-container">
            <h3 className='country-tidbits-title'>What I've Learned</h3>
            {genInfo.thoughts.map((item, index) =>
            {
              return (
                <p className="country-tidbits" key={index}>
                  {item}
                </p>)
            })}
          </div>
        </div>
      </section>

      <CountryAttractions />
      <Footer/>
    </>
  )
}