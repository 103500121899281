import React, {useEffect} from 'react';

// Data
import {mainData} from './Data/hikeLinkData.js';

// Components
import { Navbar } from '../Navbar';
import {Footer} from './../Footer.js';
import {HikingLinks} from './HikingLinks.js';


export const HikingPage = () =>
{
  useEffect((() =>
  {
    const position = window.innerHeight;

    if(position !== 0)
    {
      window.scrollTo(0,0);
    }

  }),[]);
  
  return (
    <>
      <Navbar/>
      <section className="hikes-page-section">
        <div className="hikes-page-intro" style={{backgroundImage: `url(${mainData.mainImgLarge})`}}>
          <span className="destination-img-title">Hikes</span>
        </div>
        
        <main className="hikes-page-main">
          <HikingLinks/>
        </main>
      </section>

      <Footer/>
    </>
  )
}