import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { AppContextProvider } from './AppContext';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

// app.use("/api", indexrouter);

// app.get('/*', function (req, res) 
// {
//   res.sendfile(path.join(__dirname, '/build/index.html'), function (err) 
//   {
//     if (err) 
//     {
//       res.status(500).send(err)
//     }
//   })
// })

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AppContextProvider>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </AppContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
