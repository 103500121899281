import React, {useEffect} from 'react'
import {useLocation} from 'react-router-dom';
import { useGlobalContext } from '../AppContext';

// Components
import { Navbar } from '../Navbar'
import {displayDropdown} from './../Data/functions.js';
import {RegionLink} from './Links/RegionLink.js';
import {Footer} from './../Footer.js';

export const Regions = () =>
{
  const globalVals = useGlobalContext();
  const setShowLinks = globalVals.setShowLinks;
  const dropdownRef = globalVals.dropdownRef;
  const location = useLocation();
 

  useEffect(() =>
  {
    displayDropdown(dropdownRef, setShowLinks);
  }, []);

  return (
    <>
      <Navbar />
      <section className="region-section">
        <main className="regions-main page-main">
          <div className="regions-main-center">
            <RegionLink/>
          </div>
        </main>
      </section>

      <Footer/>
    </>
  )
}