
export const displayDropdown = (dropdownRef, setShowLinks) =>
{
  
  if(dropdownRef.current.getBoundingClientRect().height > 0)
    {
      dropdownRef.current.style.height = '0px';
      dropdownRef.current.style.padding = '0px';
      setShowLinks(false);
    }
}