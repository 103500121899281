import React, {useEffect} from 'react';
import {Navbar} from '../Navbar.js';
import {FoodLink} from './FoodLink.js';
import { Footer } from '../Footer.js';

export const FoodPage = () =>
{
  useEffect((() =>
  {
    const position = window.innerHeight;

    if(position !== 0)
    {
      window.scrollTo(0,0);
    }

  }),[]);

  return(
    <>
      <Navbar/>
      
      <section className="food-section">
        <main className='food-page-main page-main'>          
          <div className="food-page-main-center">
            <FoodLink/>
          </div>
        </main>

        <Footer/>
      </section>

      
      
    </>
  )
}