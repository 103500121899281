import React from 'react';
import {Routes, Route} from 'react-router-dom';

// Data
import {destinationLinks} from '../Data/linksData.js';

// Componenets
import {DestinationsPage} from './../DestinationsPage.js';
import {Destination} from './../Destination.js';


export const DestinationRoutes = () =>
{
  return (
    <Routes>

      <Route index element={<DestinationsPage/>} />

      {destinationLinks.map((item, index) =>
      {
        return(
          <Route path={item.path} element={<Destination/>} key={index}></Route>
        )
      })}
    </Routes>
  )
}