import React from 'react';
import { Link } from 'react-router-dom';


export const ServicesArticle = (props) =>
{
  return (
    <article className="services-article">
      <img src={props.img} className='services-article-img' alt="" />
      <div className="services-article-info">
        <h4>{props.title}</h4>
        <p>
          {props.desc}
        </p>

        <button className="services-btn">
          <Link to={props.link}>Explore</Link>
        </button>
      </div>
    </article>
  )
}