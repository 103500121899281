// Food Links Object Data Format
// name: '', img:'', 
// path: 'tennesseevalleybeach', search: '?index=0',
// desc: ``,
// tags: `california marin county united states beach cliffs`,
// intro: ``


// Last index used 9
export const foodLinkData = 
[

  {
    name:`Butcher Crown Roadhouse`, img:'/photos/Food/US/ButcherCrown/thumbnail.jpg', 
    path: 'butchercrownroadhous', search: '?index=8',
    desc: `A must try for anyone who's ever wanted a brisket taco or burger`,
    tags: `bbq burgers tacos california petaluma`,
  },

  {
    name:`Tamalpie Pizza`, img:'/photos/Food/US/Tamalpie/thumbnail.jpg', 
    path: 'tamalpie', search: '?index=9',
    desc: `Artisan Pizza with fresh and organic ingredients.`,
    tags: `pizza california marin county mill valley`,
  },

  {
    name: 'Eggspresso', img:'/photos/Food/US/Eggspresso/thumbnail.jpg', 
    path: 'eggspresso', search: '?index=6',
    desc: `A breakfast paradise specializing in egg sandwhiches`,
    tags: `eggspresso breakfast pataluma sonoma county california`,
  },

  {
    name: 'Ramen Station', img:'/photos/Food/US/RamenStation/thumbnail.jpg', 
    path: 'ramenstation', search: '?index=7',
    desc: `A unique experience that simulates a Japanese train station`,
    tags: `ramen oakland california`,
  },

  {
    name:`Picante`, img:'/photos/Food/US/Picante/thumbnail.jpg', 
    path: 'picante', search: '?index=2',
    desc: `A literal hiddem gem to get Mexican food in Marin`,
    tags: `pizza california east bay`,
  },


  {
    name:`Amy's Drive Thru`, img:'/photos/Food/US/Amys/thumbnail.jpg', 
    path: 'amysdrivethru', search: '?index=3',
    desc: `A brilliant company that is revolutionizing vegetarian food`,
    tags: `burgers pizza vegetarian vegan marin california`,
  },

  {
    name:`Side Street Kitchen`, img:'/photos/Food/US/SideStreet/thumbnail.jpg', 
    path: 'sidestreetkitchen', search: '?index=4',
    desc: `Farm to table and slow cooked cuisine`,
    tags: `point reyes california marin farm to table slow cooked`,
  },

  {
    name: 'Equator Coffee', img:'/photos/Food/US/Equator/thumbnail.jpg', 
    path: 'equatorcoffee', search: '?index=5',
    desc: `A popular coffee around Marin and the Bay Area`,
    tags: `california marin county united coffee Bay Area`,
  },



  // {
  //   name:`Zachary's Pizza`, img:'', 
  //   path: 'zacharyspizza', search: '?index=0',
  //   desc: `One of the best pizzas I've ever had, it'll change you what good pizza is`,
  //   tags: `pizza california east bay`,
  // },
]

// Last Index used 9, tamalpie