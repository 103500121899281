// Template for the destinationData object
/*
{
  title: 'Sausalito', mainImg: '/photos/Destinations/Locations/', 
  intro: ``,
  elements: 
    [
      {title: '', img: '', paragraph: ``},
      {title: '', img: '', paragraph: ``},
      {title: '', img: '', paragraph: ``},
    ]
},
*/

export const destinationData = 
[
  {title: 'Sausalito', mainImg: '/photos/Destinations/Locations/Sausalito/main.jpg',
   intro: `As the first town north of the Golden Gate bridge, downtown Sausalito is located on the water with stunning views of the San Francisco and the bay. With all the shops, restaurants, and its proximity to the Marin Headlands it guarantees an amazing time for everyone of all ages. Day or night, Sausalito is a wonderful place to visit, as the city lights take over with such grandeur that grabbing dinner with a view is a must. `, 
   elements: 
   [
      {title: 'Dates & Special Occasions', img: '/photos/Destinations/Locations/Sausalito/Hiking.jpg', paragraph: `Whether you are celebrating a birthday, anniversary, or having a first date, Sausalito has a restaurant to fit every occasion. The Trident or Scoma’s are perfect for anniversaries or a anniversaries. Angelino’s is perfect for a first date with a table with a view, with amazing food and a great atmosphere. Once dinner is over, take a stroll down the promenade you'll find delight in the sweetest places for a perfect ending to your night.`},
      {title: 'Day Trips', img: '/photos/Destinations/Locations/Sausalito/Shoping.jpg', paragraph: `I absolutely recommend a day trip to Sausalito, given its size you can decide how much or how little of Sausalito you’d like to explore. Downtown Sausalito is where a majority of the shops are located, and walking from one end to other can be done in a short period of time. With so much to see and do, you can easily spend half the day looking at all the shops, taking pictures, and eating. \n
                                                                                                    Sausalito is also perfect for those who have come abroad, as once you park the town if very easy to navigate. You can also reach Sausalito through public buses, the ferry, or Uber. If you are traveling with your luggage in the car, there is a paid parking lot next Angelino’s for extra security.`},
      {title: 'Kids and Family', img: '', paragraph: `For those traveling Sausalito is a wonderful choice, as it has great options for everyone to enjoy. For those with young children, there are sit down restaurants for the family such as Fish, Salito’s Crab House, and others. For those with older children, there some great upscale restaurants such Sushi Ran, Poggio Trattoria, and many others. \n
                                                      For those looking for an adventure, the Bay Area Discovery Museum is family favorite as it has hands on exhibits that allows children learn and interact with them as they have fun. Another family favorite is exploring the Marin Headlands, where you can hike, go to the beach, explore old structures from WWII as the Nike Missile site`},
    ]
  },


  {title: 'Mill Valley', mainImg: '/photos/Destinations/Mill_Valley/main.jpg',
   intro: `Nestled at the bottom of Mt. Tamalpais, Mill Valley is a must stop for those visiting Marin County. Known for its beauty, Mill Valley has a special way of capturing the hearts of those who visit. When asked, it’s difficult to pinpoint that exact reason as to what makes it so beautiful. Which makes it a must for you to come and decide for yourself. `, 
   elements: 
   [
      {title: 'Day Trips', img: '/photos/Destinations/Mill_Valley/image_1.jpg', paragraph: `Mill Valley is a perfect place to start a great adventure because its geographical location gives you access to so many locations. The downtown area has shops, restaurants, and galleries that perfectly capture its small-town feel. The downtown area is a great place to walk around and spend time with friends and family.
                                                                                            \nOne of the best features about Mill Valley is that It is a great starting point for places such as Stinson beach, Muir Woods, Point Reyes National Seashore, or San Francisco.`},
      {title: 'Dates and Special Occasions', img: '/photos/Destinations/Mill_Valley/image_2.jpg', paragraph: `Mill Valley is a great place to take someone out on a date as its small town charm makes a wonderful backdrop. If you're on a first or second date, walking around the downtown area is a great way to keep a conversation going in a fun and relaxed manner.  \n\n As far as special occasions, Mill Valley is a perfect place to celebrate birthdays, anniversaries, you name it. Buckeye Road House, Bungalow 44, Playa (which is the Michelin Bib Gourmand) distinction, are some of the excellent restaurants to hold these special events. `},
      {title: 'Tourists from Abroad', img: '/photos/Destinations/Mill_Valley/image_3.jpg', paragraph: `Mill Valley is a great destination for those coming abroad, as it is a great starting point to a lot of destinations and activities. Whether you're looking to go to the city, the beach, hiking, or drive Highway 1, Mill Valley has access to all of them. It is also a safe town with a low crime rate, where you can find many kids and families walking around. If you don't have access to a car I'd highly recommend you rent a bike. Bikes can be rented at Mad Dogs & Englishmen Bike Shop (Downtown Mill Valley) and Xtracycle (Tam Junction, close to Muir Woods). `},
    ]
  },


  {title: 'Tiburon', mainImg: '/photos/Destinations/Tiburon/cover.jpg',
   intro: `Located on a small peninsula North of the Golden Gate Bridge, Tiburon is a small quiet town with amazing shopping and restaurants in its downtown area. Known for its beauty, Tiburon is a great town to walk around with breathtaking views.`, 
   elements: 
   [
      {title: 'Day Trips', img: '/photos/Destinations/Tiburon/image_1.jpg', paragraph: `Tiburon is a great place to visit if you're planning a day trip to Marin. It's a wonderful option if you're looking for a place to eat and take some pictures.It is also a perfect option for a biking trip, where many bike from San Francisco or neighboring Sausalito.`},
      {title: 'Special Occasions', img: '/photos/Destinations/Tiburon/image_2.jpg', paragraph: `Whether it is a first date, second, or an anniversary Tiburon is a perfect location to take a significant other. What makes Tiburon so ideal is the amount of flexibility that such a small location has to offer. You can make it as casual or upscale as you'd like. If you'd like coffee-date casual, Caffe Acri and a walk along the waterfront is perfect. If you're looking for a more intimate setting, a table with a view of the bay at the Caprice is magical.`},
      {title: 'Tourist from Abroad', img: '/photos/Destinations/Tiburon/image_3.jpg', paragraph: `If you are new to the United States, I'd recommend coming to Tiburon. Its downtown area is small and very easy to navigate, and can be explored by walking around. It is also a very safe town with a low crime rate. When it comes to food, this is not the most budget friendly town, so I'd recommend grabbing something to eat at Nugget Market.`},
    ]
  },

  {title: 'Golden Gate Bridge',  mainImg: '/photos/Destinations/GGBridge/main.jpg',
   intro: `Constructed at the entrance of the San Francisco Bay, the Golden Gate Bridge connects the San Francisco Peninsula to Marin County. With construction completed on May 27, 1937, the Golden Gate Bridge has become one of the Wonders of the Modern World. Whether you drive, walk across, or  view it across the Marin Headlands, the bridge has become a staple of a Bay Area vacation. `, 
   elements: 
   [
      {title: 'Walking', img: '/photos/Destinations/GGBridge/image_1.jpg', paragraph: `If you're interested in walking across the bridge, pedestrians are permitted on the East side of the bridge. There are no fees to walk across the bridge. \n\nEarly November to Early March: 5:00am-6:30pm\nEarly March to Early November: 5:00am-9:00pm`},
      {title: 'Parking', img: '/photos/Destinations/GGBridge/image_2.jpg', paragraph: `Given its popularity with tourists, parking near the Golden Gate Bridge has become a hotspot for criminals. If you do decide to park near the bridge, there are some precautions you need to take. First, do not leave any valuables in plain sight. This goes as far as leaving car chargers and loose change. Some people recommend leaving valuables locked in the trunk of your car. What some people don't consider is that if you park and then place all your valuables in the trunk, criminals will be observing what is being placed away. My recommendation is for you to leave all your valuable belongings at the hotel. If you must bring your valuables with you in the car, consider first placing them in the trunk then driving to the bridge. `},
      {title: 'Parking: Southeast Side', img: '/photos/Destinations/GGBridge/image_4.jpg', paragraph: `You can park on the Southeast side of the bridge close to the visitor center, which has 37 spots in total (26 general parking, 6 bus spots, and 3 handicap). Parking is $5/hour with a maximum 3 hours of consecutive parking.
                                                              \nIf you're traveling South from Marin County you'll need to be in the right-most lane, once you pass the toll plaza you'll take the first immediate exit onto Merchant Road. You'll then take an immediate right on Cranston Road, where you'll take another right where you'll go through an underpath that'll take you straight to the Southeast visitor center.\n\nIf you're traveling North from San Francisco, you'll take the last exit before the toll plaza marked as "Golden Gate National Recreation Area View Area". `},
      {title: 'Parking: Northeast Side', img: '/photos/Destinations/GGBridge/image_6.jpg', paragraph: `There is also parking available on the Northeast side of the bridge. This vista point is free to park, has 135 spots, with no time limit.\n\nIf you're traveling North from San Francisco, you'll need to take the exit called "Vista Point" and take a right into the parking lot. If you're traveling South from Marin County, there is no way to get to the parking lot without first crossing the bridge.`},
      {title: 'Biking',  img: '/photos/Destinations/GGBridge/image_3.jpg', paragraph: `Another great option that many people enjoy is biking across the Golden Gate Bridge. Some of the great benefits to cycling across the Golden Gate Bridge is that you'll have access to the West side of the bridge. If you're visiting the area without a bike, many choose to rent bikes either in San Francisco or Marin County. Which side of the bridge you can ride on during which time gets a little complicated so plan accordingly.`},
      {title: 'Biking - First Sunday in November to second Monday in March', img: '/photos/Destinations/GGBridge/image_5.jpg', paragraph: `Weekdays
                                            5:00 am to 3:30 pm: East sidewalk
                                            3:30 pm to 6:30 pm: West sidewalk
                                            6:30 pm to 5:00 am: East sidewalk - You'll have to use a buzzer where a security guard will let you in once they verify you're a cyclist. 

                                            Weekends and Holidays
                                            5:00 am to 6:30 pm: West sidewalk
                                            6:30 pm to 5:00 am East sidewalk - You'll have to use a buzzer where a security guard will let you in once they verify you're a cyclist. `},
      {title: 'Biking - Second Monday in March to first Sunday in November', img: '/photos/Destinations/GGBridge/image_7.jpg', paragraph: `Weekdays
                                            5:00 am to 9:00 pm: East sidewalk
                                            3:30 pm to 9:00 pm: West sidewalk
                                            9:00 pm to 5:00 am: East sidewalk - You'll have to use a buzzer where a security guard will let you in once they verify you're a cyclist. 

                                            Weekends
                                            5:00 am to 9:00 pm: East & West sidewalks
                                            9:00 pm to 5:00 am: East sidewalk - You'll have to use a buzzer where a security guard will let you in once they verify you're a cyclist.`},
      // {title: '', img: '', paragraph: ``},
      // {title: '', img: '', paragraph: ``},
    ]
  },


  {
    title: 'Santa Rosa',  mainImg: '/photos/Destinations/SantaRosa/main.jpg', 
    intro: `Located North of San Francisco, Santa Rosa is filled with wineries, restaurants, and outdoor activities. It is also the home to world famous Pliney of the Elder beer, Charles M Schulz museum, and Luther Burbank. It is great for couples looking for a weekend getaway and families with young kids.`,
    elements: 
    [
      {title: 'Food', img: '', paragraph: `As a part of wine country, Santa Rosa has a great food scene with a wide variety of options. Once parked in the downtown area, you'll be able to choose from great restaurants such as Russian River Brewery (which produces the Pliney of the Elder), El Coqui serving the best Puerto Rican food I've ever had, or Ca'Bianca serving some of the best Italian food in the city. Santa Rosa also has many fantastic restaurants outside of the downtown area such as Roku Ramen and Jeffery's Hillside Cafe.`},
      {title: 'Spend the Day with the Family', img: '', paragraph: `If you're looking for a place to spend the day with family, Santa Rosa is a great option. For families with kids I'd recommend visiting the Charles Schulz Museum, the Snoopy Ice Rink, or taking a safari trip at Safari West. Many families also enjoy the Wednesday Night Market, with a Farmers Market type atmosphere.`},
      {title: 'Date Night ', img: '', paragraph: `Santa Rosa is a perfect place for a date Night. Whether you're on a casual date or something more intimate, you'll be able to find something that'll have the right atmosphere. For those looking to celebrate an anniversary, the wineries are a wonderful option. For those on a first date I'd recommend walking around the downtown area and checking out Treehorn bookstore, which is an amazing way to get to know each other.`},
    ]
  },

  {
    title: 'Montara Beach', mainImg: '/photos/Destinations/Montara_Beach/main.jpg', 
    intro: `Whether you're looking for a beach to spend the day or a quick stop, Montara State Beach is a perfect choice. While there you'll be able to hike the paths on top of the bluffs that overlook the Pacific Ocean or head down to the beach. There are no parking or entrance fees`,
    elements: 
      [
        {title: 'The Bluffs', img: '/photos/Destinations/Montara_Beach/image_1.jpg', paragraph: `One great part of Montara Beach are the bluffs that you can explore. If you don't feel like heading down to the beach and getting sandy, the bluffs have paths you can walk along that have great views of the beach and the Pacific Ocean. They are well maintained and are easily accessible from the parking lots. Be careful near the edges of the bluffs as they are unstable due to erosion.`},
        {title: 'The Beach', img: '/photos/Destinations/Montara_Beach/image_2.jpg', paragraph: `If you're limited with time, I'd recommend parking in the South lot as it has a set of stairs with quick access to the beach, with bathrooms available in the North parking lot.The beach is also popular for surfing, fishing, sunbathing, and dog owners. Montara Beach does lie in the fog belt so be prepared for fog and strong winds.`},
        // {title: '', img: '', paragraph: ``},
      ]
  },

  {
    title: 'Mavericks Beach', mainImg: '/photos/Destinations/Mavericks_Beach/main.jpg', 
    intro: `Home to the legendary Maverick waves and surf competition, this beach produces some of the most awe inspiring waves during the winter months. As a relatively small beach, there is much to explore during low tide.`,
    elements: 
      [
        {title: 'Pillar Point Harbor', img: '/photos/Destinations/Mavericks_Beach/image_3.jpg', paragraph: `Other than the small handful of humans that are actually capable of surfing the Mavericks big waves, the Maverick's beach itself is a wonderful place to visit. Many visit this beach to walk their dogs, explore the tidepools, and view wildlife. The Point Pillar Harbor gives the West Shoreline protection from the swell and the wind, making it a great place to sit and relax.`},
        {title: 'Tide Pools (Check Tide Charts)', img: '/photos/Destinations/Mavericks_Beach/image_1.jpg', paragraph: `One great feature of this beach are the tide pools you can explore. When the weather permits and during low tide, taking a stroll along the beach is an enjoyable experience for the whole family. When there is a negative low tide, you are able to walk out and get great views of the open ocean.`},
        // {title: '', img: '', paragraph: ``},
      ]
  },

  {
    title: 'Point Bonita Lighthouse', mainImg: '/photos/Destinations/BonitaLighthouse/main.jpg', 
    intro: `Still active to this day, the Point Bonita Lighthouse is located in the Golden Gate National Recreation Area, just North of San Francisco. The trail and the lighthouse offer stunning views of the Golden Gate Bridge, the Pacific Ocean, and the Cliffs. There is limited parking. \n\nMonday: 12:30pm-3:30pm\nTuesday: Closed\nWednesday: Closed\nThursday: 12:30pm-3:30pm\nFriday: 9:30pm-3:30pm\nSaturday: 9:30pm-3:30pm\nSunday: 9:30pm-3:30pm`,
    elements: 
      [
        {title: 'The Trail', img: '/photos/Destinations/BonitaLighthouse/image_1.jpg', paragraph: `The trail is 1.1 miles round trip, with one steep section as you approach the tunnel. It is common for the area to face strong winds, so be prepared with a windbreaker and to hold onto your hats. It is also common for Park Rangers to close the bridge to the lighthouse due to strong winds.`},
        {title: 'The Bridge', img: '/photos/Destinations/BonitaLighthouse/image_2.jpg', paragraph: `An experience in itself, the bridge to the lighthouse is not only absolutely beautiful, but very fun to walk across. Be prepared, as once you see it, you'll definitely want to take that perfect picture. What is most enjoyable or scary, depending on how you view it, is feeling the bridge move as the wind blows. Before you walk across the bridge I recommend taking off your hat or anything that may blow away from the strong winds.`},
        {title: 'The Lighthouse', img: '/photos/Destinations/BonitaLighthouse/image_3.jpg', paragraph: `Built in 1855, the Point Bonita Lighthouse is still operational and open to the public. Once you cross the bridge visitors will be able to go inside of the lighthouse where you can escape the wind for a bit. You'll also have stunning views of the Pacific Ocean and the coastline. Bringing a jacket is recommended as you can expect high winds while there. Overall, this is a great place to visit, especially if you're short on time as it shouldn't take more than an hour once you park.`},
      ]
  },
]