import React from 'react';
import {Link} from 'react-router-dom';

// Data
import {destinationLinks} from './Data/linksData';

export const DestinationLinks = () =>
{

  return(
    destinationLinks.map((item, index) =>
    {
      console.log(item.img);
      return (
        
        <div className='destination-link' to={item.path} key={index}>
          
            <img className='destination-article-img' src={item.img} alt="" />
            <span className='destination-link-title'>{item.title}</span>
            <p className="destination-link-desc">{item.desc}</p>

            <Link to={{pathname: item.path, search: item.search}}>
              <button className="services-btn">Explore</button>
            </Link>
          
        </div>
      )
    })
  )
}