import React from 'react';
import {Routes, Route} from 'react-router-dom';
import {regionsLinksData} from '../DataFolder/data';
import {AreaRoutes} from './AreaRoutes.js';

// Components
import {Regions} from '../Regions';
import {Country} from '../Country';
import {DestinationsPage} from './../../Destinations/DestinationsPage.js';



export const RegionRoutes = () =>
{

  return (
    <Routes>
      <Route index element={<Regions/>}/>
      {
        regionsLinksData.map((item, index) =>
        {
          return (
            <Route path={item.path} state={index} key={index} element={ <Country/>}> 
            </Route>
          )
        })
      }
    </Routes>
  )
}