import React from 'react';
import {Routes, Route} from 'react-router-dom';
import {HomePage} from './HomePage/HomePage.js';
import {RegionRoutes} from './Regions/Routes/RegionRoutes.js';

// Componenets
import {Gear} from './Gear/Gear';
import {BudgetCalculator} from './Budget/BudgetCalculator.js';
import {Stores} from './Stores/Stores.js';
import {About} from './About/About.js'

// Routes
import {FoodRoutes} from './Food/FoodRoutes.js';
import {DestinationRoutes} from './Destinations/Routes/DestinationRoutes.js';
import {HikingRoutes} from './Hikes/Routes/HikingRoutes.js';

import './App.css';

// const getElementsBiggerThanViewport = () => {
//   const docWidth = document.documentElement.offsetWidth;
//   return [...document.querySelectorAll('*')].filter(
//     el => el.offsetWidth > docWidth
//   );
// };

function App() {
// console.log( getElementsBiggerThanViewport());
document.title = 'Cortez Travel Guide';


  return (
    <Routes>
      <Route path='/' location='/' element={<HomePage/>}></Route>
      <Route path='/Regions/*' element={<RegionRoutes/>} ></Route>
      <Route path='/Gear' element={<Gear />}></Route>
      <Route path='/Destinations/*' element={<DestinationRoutes/>}></Route>
      <Route path='/Food/*' element={<FoodRoutes/>}></Route>
      <Route path='/hikes/*' element={<HikingRoutes/>}></Route>
      <Route path='/Stores' element={<Stores/>}></Route>
      <Route path='/budgetcalculator' element={<BudgetCalculator/>} />
      <Route path='/About' element={<About/>}></Route>
    </Routes>
  );
}

export default App;
