import React, {useEffect} from 'react';
import { Navbar } from './../Navbar';
import { useGlobalContext } from '../AppContext';
import { displayDropdown } from '../Data/functions';
import {MainSection} from './MainSection.js'
import { Services } from './Services';
import { Footer } from '../Footer';


export const HomePage = () =>
{
  const globalVals = useGlobalContext();
  const dropdownRef = globalVals.dropdownRef;
  const setShowLinks = globalVals.setShowLinks;


  useEffect(() =>
  {
    displayDropdown(dropdownRef, setShowLinks);
  }, []);



  return (
    
    <section className='homepage'>
      <Navbar/>
      <MainSection />
      <Services/>
      <Footer/>
    </section>
  )
}