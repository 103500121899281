import React, {useEffect} from 'react';

// Data 
// import { hikingLinks } from './Data/hikeLinkData';
import {hikingData} from './Data/hikeData.js';


// Components
import {Navbar} from './../Navbar';
import {Footer} from './../Footer';
import {Sidebar} from './../Sidebar/Sidebar.js';


export const Hike = () =>
{
  const params = new URLSearchParams(window.location.search);
  const index = params.get('index');
  const data = hikingData[index];
  
  useEffect((() =>
  {
    const position = window.innerHeight;

    if(position !== 0)
    {
      window.scrollTo(0,0);
    }

  }),[]);


  return(
    <>
      <Navbar/>

      <header className="hike-section-intro" style={{backgroundImage: `url(${data.img})`}}>
        <div className="hike-intro-modal">
          <span className='hike-header-title'>{data.name}</span>
        </div>
      </header>

      <section className="hike-section">
        <main className="hike-main">

          <ul className="general-info">
            {data.generalInfo.map((info, index) =>
            {
              return <li className='gen-info-li' key={index}><span className="blue-text">{info.title}</span>: {info.text}</li>
            })}

            {data.webLinks.length !== 0? data.webLinks.map((info, index) =>
            {
              return (
                <li className='gen-info-li' key={index}>
                  <span className="blue-text">{info.title}</span>: <a href={info.url} rel="noreferrer" target='_blank'>{info.text}</a>
                </li>
              )
            }): <></>}
          </ul>

          <p className="hike-intro-paragraph">{data.intro}</p>

          <div className="topic-container">
            {data.elements.map((item, index) =>
            {
              return (
                <div className="destination-topic" key={index}>
                  <span className="destination-topic-header">{item.title}</span>
                  <img src={item.img} alt="" className="destination-topic-img" />
                  <p className="destination-topic-paragraph">{item.paragraph}</p>
                </div>
              )
            })}
          </div>
        </main>
        
        <Sidebar id='hike-sidebar'/>
      </section>

      <Footer/>
    </>
  )
}