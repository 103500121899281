// import React from 'react';

export const isValidNumber = (num, message, ref) =>
{
  if(typeof num === 'string' && num.length === 0)
  {
    ref.current.classList.remove('display-none');
    ref.current.innerHTML = message;
    setTimeout(() => 
      {
        ref.current.innerHTML = "Test";
        ref.current.classList.add('display-none');
      },3000);
    return false;
  }

  if(isNaN(num) || num > 100000000)
  {
    ref.current.classList.remove('display-none');
    ref.current.innerHTML = message;
    setTimeout(() => 
      {
        ref.current.innerHTML = "Test";
        ref.current.classList.add('display-none');
      },3000);

    return false;
  }

  return true;
}


export const validateFlights = (num, message, ref) =>
{
  // const error = isValidNumber(num, message, ref); 
}


