import React, {useEffect} from 'react';


// Componenets
import {Navbar} from './../Navbar.js';
import {Footer} from './../Footer.js';
import {DestinationLinks} from './DestinationLinks.js';


export const DestinationsPage = () =>
{
  useEffect((() =>
  {
    const position = window.innerHeight;

    if(position !== 0)
    {
      window.scrollTo(0,0);
    }

  }),[]);

  return (
    <>
      <Navbar/>
    
      <section className="destinations-page">

        <div className="destination-articles-container">
          <DestinationLinks />
        </div>
        
      </section>

      <Footer/>
    </>
  )
}