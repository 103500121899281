// {
//   name: '',  img:'',  
//   intro: ``,
//   generalInfo: 
//     [
//       {title: '', text: ``},
//       {title: '', text: ``},
//       {title: '', text: ``},
//       {title: '', text: ``},
//       {title: '', text: ``},
//     ],
//   webLinks: [],
//   elements: 
//   [
//       {title: '', img: '', paragraph: ``},
//       {title: '', img: '', paragraph: ``},
//       {title: '', img: '', paragraph: ``},
//   ],
// },




export const restaurantData = 
[
  {
    name: `Zachary's Pizza`,  img:'/photos/Food/Restaurant/food.jpg',  
    intro: `With its Chicago style piazza, Zachary's might just be one of the best pizza places in the Bay Area, and could well change your definition of what good pizza is. For those of you who have never tried Chicago style pizza you can expect a crispy golden brown crust, cheese that stretches so much that the employees have a special technique for serving each slice, and their zesty tomato sauce with wonderful balance of herbs and pieces of tomatoes that'll explode with flavor.  `,
    generalInfo: 
      [
        {title: 'Hours', text: `Sun-Thurs: 11:00am- 8:30pm, Fri-Sat: 11:00am- 9:00pm`},
        {title: 'Food', text: `Pizza and Appetizers`},
        {title: 'Location', text: `1853 Solano Ave, Berkeley CA`},
        {title: 'Parking', text: `Street parking`},
        {title: 'Reservations', text: `No reservations are required. First come first serve`},
        {title: 'Expections', text: `During peak hours wait time to be seated can be from 45-60 minutes. Wait time for deep dish pizzas can be and additional 45 minutes.`},
      ],
    webLinks: 
      [
        {title: 'Website', text: `zacharys.com`, url: `https://zacharys.com/`},
      ],
    elements: 
    [
        {title: 'Food', img: '', paragraph: `Zachary's pizza has become a special location for so many people around the Bay Area, from family dinners, to birthdays, and even dates, it has become a go-to for obvious reasons only for those who have tried their pizza. Zachary's seems to have mastered the three biggest aspects of pizza, and that is the crust, the cheese, and the sauce. Right out of the oven you can expect a crispy golden brown crust, cheese so stretchy that employees have a special technique to server each slice, and sauce with a perfect combination of herbs and pieces of tomatoes that explode with flavor. My recommendation is always that people get the large deep dish pizza as left-overs are wonderful joy of life.`},
        {title: 'Wait Time', img: '', paragraph: `Due to the density and quality of the deep dish pizzas you should expect a long wait time as they can take 30-45 minutes to bake, but, I can guarantee you that the pizza is worth the wait. During dinner time, especially on Friday and Saturday nights you can also expect to wait 45-60 minutes to be seated, and if you find yourself waiting I recommend checking out Pegasus Book Store or taking a stroll down the beautiful Solano Ave. `},
        {title: 'Tourists from Abroad', img: '', paragraph: `Even though pizza can be found in many countries with their own take and style, Chicago style, true to it's name is unique to Chicago and the Mid-West, but can now be found all over the United States. If you're a pizza lover and you find yourself in the Bay Area, then Zachary's is a must try. Given the wait time, I recommend you leave yourself ample time of at least 2 hours to stop and eat. `},
    ],
  },


  {
    name: `Phyllis' Giant Burger`,  img:'/photos/Food/US/PhylisBurger/image_1.jpg',  
    intro: `An absolute gem in downtown Mill Valley, Phyllis' will not disappoint if you're looking for a good burger. A favorite amongst locals, they have a wide selection of burgers that include beef, chicken, buffalo, and even an impossible burger. If you've had a long day of hiking or exploring you cannot forget about their build-your-own milk shakes (my favorite: chocolate, peanut butter, and banana).`,
    generalInfo: 
      [
        {title: 'Food', text: `Burgers, Fries, Salads, Milkshakes, and Icecream`},
        {title: 'Hours', text: '11:00am-8:00pm, Everyday'},
        {title: 'Location', text: ' 8 East Blithedale Ave, Mill Valley, CA 94941'},
        {title: 'Parking', text: `Street Parking`},
        {title: 'Reservations', text: `No reservations, first come first serve`},
      ],
    webLinks: 
      [
        
      ],
    elements: 
    [
        {title: 'Food', img: '/photos/Food/US/PhylisBurger/image_3.jpg', paragraph: `Phyllis' is definitely one of the best burger places in Marin County, as it has won "Best Burger" in Marin by the Pacific Sun several times. After a 20 mile hike, a burger, chili cheese fries, and a milkshake is one of the best ways to end the day. One the best part about Phyllis' is the variety that their menu offers, as you can choose from a traditional burger to something as special as a buffalo burger.\n\nPersonally, I love their BBQ burger, with sauteed onions and bacon, topped with a brioche bun. As good as their burgers are, you cannot forget about their fries. Their french fry menu offers cheesy fries, chili cheese fries, garlic fries, and many others.\n\n If burgers aren't your favorite, I'd definitely stop by and grab some dessert before they close, as they offer both soft-serve ice cream and milkshakes. I'd definitely try their milk shakes as you can build your own creation (chocolate, peanut butter, and banana is my favorite).`},
        // {title: '', img: '/photos/Food/US/PhylisBurger/image_2.jpg', paragraph: ``},
        // {title: '', img: '', paragraph: ``},
    ],
  },


  {
    name: `Picante`,  img:'/photos/Food/US/Picante/main.jpg',  
    intro: `A hidden gem in San Rafael, Picante has some of the most delicious Mexican food you may find in Marin County. It has all the best qualities: good portions, fresh ingredients, and affordable. Best yet, they have a refrigerator stocked with bottles of their homemade salsas that you can grab and take to your table.`,
    generalInfo: 
      [
        {title: 'Food', text: `Mexican Cuisine`},
        {title: 'Hours', text: 'Sun-Thur: 8:00am-9:00pm, Fri-Sat: 8:00am-10:00pm'},
        {title: 'Parking', text: `They have a parking lot and street parking`},
        {title: 'Location', text: `340 Bellam Blvd, San Rafael, CA 94901`},
        {title: 'Reservations', text: `No reservations, first come first serve`},
      ],
    webLinks: 
      [],
    elements: 
    [
        {title: 'Delicious Food', img: '/photos/food/US/Picante/image_1.jpg', paragraph: `With fresh ingredients and delicious recipes, Picante is the epitome of a hidden gem, that they have gained through years of hard work. Their menu has all the classics that you'd expect from taqueria, as well as some wonderful veggie burritos (such as their Veggie Butternut Squash). What makes this place popular amongst locals is the portion-to-quality ratio that you'll get at an affordable price. With an a large amount of tables this is a great place to come with your family or a group of friends.`},
        {title: 'Homemade Salsas', img: '/photos/Food/US/Picante/image_2.jpg', paragraph: `One of the best parts of Picante is there homemade salsas that you can grab and take back to your table. Located at the front in a small refrigerator, they have a variety of green, red, and a very spicy habanero salsa. Since they're in a squeeze bottle, every bite of your food can be flavorful and with the right amount of salsa. This is especially amazing if you get their Super Nachos, as you'll never end up with an empty chip during your time there.`},
        // {title: '', img: '', paragraph: ``},
    ],
  },

  {
    name: `Amy's Drive Thru`,  img:'/photos/Food/US/Amys/main.jpg',  
    intro: `Amy's drive through has crafted one the most delicious menus serving American style food that may blow your mind. Looking at their menu you'll see that they sell burgers, salads, fries, pizza, and chili, which is to no one's surprise. What makes Amy's so special is the fact that their menu is organic and vegetarian. They also happen to be a "fast-food" restaurant, while still keeping their food quality high.`,
    generalInfo: 
      [
        {title: 'Hours', text: 'Sun-Thur: 11:00am-9:00pm, Fri-Sat: 11:00am-10:00pm'},
        {title: 'Location(s)', text: 'Corte Madera, Rohnert Park, SFO, Rosevill, Thousand Oaks'},
        {title: 'Parking', text: `They have a dedicated parking lot and a drive-through as well`},
        {title: 'Food', text: `Their menu is vegetarian with some vegan options`},
      ],
    webLinks: 
      [
        {title: 'Website', text: `amysdrivethru.com`, url: `https://amysdrivethru.com/`},
      ],
    elements: 
    [
        {title: 'Food', img: '/photos/Food/US/Amys/image_1.jpg', paragraph: `Whether or not you are vegetarian, Amy's Drive Thru is one of the best experiences you can get from a "fast food" restaurant. Using fresh organic, non-gmo ingredients, they've created an amazing classic American menu. For all you non-vegetarians like me, this place will quickly gain your respect with their burgers, chili cheese fries, piazza's and milkshakes. What also makes Amy's so amazing is that their prices are very affordable, making their quality-to-price ratio almost a steal.`},
        {title: 'The Amy', img: '', paragraph: `Maybe one the highlights of their menu "The Amy", is their double veggie patty burger that is spectacular. It has great flavor, filling, and is a must try even for non-vegetarians. For me, it tastes like a hash brown made into a burger. It also includes lettuce, tomatoes, onions, and  their Fred Sr.'s secret sauce (regular or spicey). If you're in a hurry  or on a road trip, this is a great option as you'll leave feeling satisfied, but not heavy and lethargic.`},
        {title: 'Variety of Choices', img: '', paragraph: `Overall, Amy's Drive Thru has great quality food even though it is technically a "fast food" restaurant. With their affordable prices you can get some great combos like, mac 'n' cheese with a tortilla soup, or their Super Salad (Seasonal Lettuces, Quinoa, Hummus, Baked Tofu, Carrots, Purple Cabbage, Pumpkin Seeds) with a side of sweet potato fries. Whatever you do, you cannot forget to add one of their milkshakes to your order, as I've made plenty of runs just for their milkshakes.`},
        {title: 'Touriests from Abroad', img: '', paragraph: `If you find yourself at the San Francisco International Airport, you're in luck as Amy's has a location there open (5:00am-9:00pm). For those fortunate to be at the SFO location I'd highly recommend that you go, as it's the only location with a breakfast menu. From 5:00am-10:00am, you'll be able to get a breakfast sandwich, breakfast burrito, bagel, acai bowl and some other breakfast items. It is located in the Harvey Milk Terminal 1, near Gate B6. Some items such as their burgers, pizzas, and mac 'n' cheese are available all day. After 10:00am they will switch over to their lunch/dinner menu`},
    ],
  },


  {
    name: `Side Street Kitchen`,  img:'/photos/Food/US/SideStreet/main.jpg',  
    intro: `An hour North of San Francisco, Side Street Kitchen is a hidden gem in Point Reyes Station. As a farm-to-table restaurant they have crafted an amazing menu that focuses on slow cooked meat and juicy spice-rubbed rotisserie chicken (which I can confirm). With so much attention focused on their craft, it would be to no one's surprise if one day they are listed on the Michelin website.`,
    generalInfo: 
      [
        {title: 'Hours', text: 'Wednesday – Friday: 9am-6pm, Saturday – Sunday: 11am-6pm, CLOSED: Monday & Tuesday'},
        {title: 'Location', text: '60 4th St, Point Reyes Station, CA 94956'},
        {title: 'Parking', text: `A few spots at the front, street parking available`},
        {title: 'Food', text: `Farm to table and slow cooked cuisine`},
      ],
    webLinks: 
      [
        {title: 'Website', text: `sidestreet-prs.com`, url: `https://sidestreet-prs.com/`},
      ],
    elements: 
    [
        {title: 'Food', img: '/photos/Food/US/SideStreet/image_1.jpg', paragraph: `When you eat at Side Street Kitchen, you understand that what makes it so special could only be achieved through years of hard work. Eating there has always been a wonderful experience for me and everyone that I've eaten there with. Sourcing their ingredients from local and regional farms, you can definitely taste the quality of their food.`},
        {title: 'Breakfast', img: '', paragraph: `If you're looking for breakfast, Side Street Kitchen is a wonderful option as their menu gives you the flexibility to select a light or heavy breakfast. If you're grabbing breakfast before hiking at the Point Reyes National Seashore, I'd recommend either their Sunny Side Quinoa Bowl or their (Granola, Yogurt, Fruit). If you need a good hearty breakfast I recommend their Wild Mushroom & Point Reyes Toma Cheese Scramble or Sourdough French Toast.`},
        {title: 'Lunch and Dinner', img: '', paragraph: `Their Roasted Half Chicken might be in my top three favorites of all time. Located in the Point Reyes Station, this is a great family location to come eat before or after a day of hiking. For those looking for a great place to go on a third or fourth date, you may want to consider Side Street Kitchen as an option. Given the long drive out here, it may not be the best for a first or second date if both of you don't know each other too well.`},
        // {title: '', img: '', paragraph: ``},
    ],
  },


  {
    name: 'Equator Coffee',  img:'/photos/Food/US/Equator/main.jpg',  
    intro: `With a total of 9 cafes around the Bay Area, the Sausalito location is a great location to grab coffee and food.With indoor and outdoor seating, this is a great stop if visiting sausalito. With two separate counters, many have been confused not knowing to pick up their food at the left counter.`,
    generalInfo: 
      [
        {title: 'Hours', text: `Mon-Fri: 7:30am-4:00pm, Sat-Sun: 7:30am-5:00pm`},
        {title: 'Location', text: `1201 Bridgeway Sausalito, CA 94965`},
        {title: 'Food', text: `Coffee, Breakfast, and Lunch`},
        {title: 'Parking', text: `Street Parking is available`},
        // {title: '', text: ``},
      ],
    webLinks: [{title: 'Website', text: `equatorcoffees.com/pages/cafes`, url: `https://www.equatorcoffees.com/pages/cafes`}],
    elements: 
    [
        {title: 'Locally Roasted Coffee', img: '/photos/Food/US/Equator/image_1.jpg', paragraph: `Their Vanilla Lavender Iced Latte was absolutely delicious, easily making its way into the top three best lattes in Marin County. If you're looking for good food and a place to eat, then you'd definitely want to check out Equator Coffee. Well known for their quality coffee, this is a go to spot for tourists and locals. Their coffee is fresh and with great flavor as they roast their beans a couple of miles away in San Rafael. The owners of Equator actually began this company by roasting beans in their garage in Mill Valley.`},
        {title: 'Food', img: '/photos/Food/US/Equator/image_2.jpg', paragraph: `This is also a great location to grab something to eat, as you'll probably be able to find something for the whole family. They have menu options for breakfast and lunch, as well as vegetarian and vegan options. This is a great place to start your day as you can grab a light or heavy breakfast. For a light breakfast I'd recommend the banana toast or yogurt granola-fruit bowl. For a heavier breakfast I'd recommend the Farmers Breakfast.`},
        // {title: '', img:'', paragraph: ``},
    ],
  },


  {
    name: 'Eggspresso',  img:'/photos/Food/US/Eggspresso/main.jpg',  
    intro: `There are two restaurants that I know of that have mastered the egg sandwhich, one is in Greece, the other is Eggspresso. Located in Petaluma, Eggspresso is North of San Francisco and makes a great breakfast stop as you make your way to Wine Country. `,
    generalInfo: 
      [
        {title: 'Hours', text: `Monday-Friday: 7:00am-2:00pm, Saturday: 7:00am-3:00pm`},
        {title: 'Location', text: `173 N McDowell Blvd, Petaluma, CA 94954`},
        {title: 'Food', text: `Amazing Breakfast Sandwiches aswell other breakfast and lunch plates`},
        {title: 'Parking', text: `Abundant parking inside lot`},
      ],
    webLinks: [{title: 'Website', text: `eggspressoco.com`, url: `https://www.eggspressoco.com/`},],
    elements: 
    [
        {title: 'The Breakfast Sandwich', img: '/photos/Food/US/Eggspresso/image_2.png', paragraph: `In the battle for the best breakfast sandwich, Eggspresso is a clear front runner. Each breakfast sandwich is crafted with the fluffiest eggs and the softest brioche bun or english muffin. With sandwiches like the "Petaluma", the "Caprese", or the "Bacon, Egg, & Cheese", they offer a great variety. The attention to detail they place on their sandwiches is outstanding, where it's easy to tell that they've honed their craft. What is also great is they use ingredients from local businesses like Petaluma Farms and Acre Coffee. `},
        {title: 'Home Fries', img: '/photos/Food/US/Eggspresso/image_1.jpg', paragraph: `Just like their breakfast sandwiches, Eggspresso has amazing home fries that may be some of the best I've ever had. They come well seasoned, crispy on the outside, soft on the inside, and never overcooked. Their home fries come as a side with each breakfast sandwich, but I might recommend getting an extra side order.`},
        // {title: '', img: '', paragraph: ``},
    ],
  },


  {
    name: 'Ramen Station',  img:'/photos/Food/US/RamenStation/main.jpg',  
    intro: `A novelty experience in the United States, Ramen Station mimics a Japanese train station self service restaurant. Where customers order their meal at a kiosk and grab their drinks and snacks at a vending machine. With delicious ramen and quick service, this is a great place to eat or to take on the go. `,
    generalInfo: 
      [
        {title: 'Hours', text: `Wednesday-Sunday: 11:30am-8:30pm`},
        {title: 'Location', text: `1211 Embarcadero, Oakland, CA 94606`},
        {title: 'Food', text: `Ramen, Japanese Drinks and Snacks`},
        {title: 'Parking', text: `Parking in shopping center parking lot`},
      ],
    webLinks: [],
    elements: 
    [
        {title: 'Ordering Food', img: '/photos/Food/US/RamenStation/image_1.jpg', paragraph: `Ordering food is quick and easy as it'll be done at a touch screen kiosk. Keeping up with Japanese customs and traditions, some will be surprised to find that there is no tipping. Ordering from the kiosk was a great experience, once you finish paying your order number will be on the receipt and will be called out once ready. One feature that I did enjoy was the ability to select if your meal was for eating-in or to-go. `},
        {title: 'Drinks', img: '/photos/Food/US/RamenStation/image_2.jpg', paragraph: `At Ramen Station you'll be able to purchase Japanese drinks and snacks from vending machines. `},
        {title: 'The Ramen', img: '/photos/Food/US/RamenStation/image_3.jpg', paragraph: `The Spicy Tonkotsu Ramen was rich and creamy with the perfect level of spiciness. The thick noodles had great texture that were neither too firm nor too soft. The toppings were also amazing, with the soft-boil egg, fresh green onions, and nori. I also chose to add bamboo shoots, which I'd highly recommend. If you're looking to take your ramen to go, they do have an option that separates the toppings from the broth to prevent them from getting soggy.`},
    ],
  },


  {
    name: 'Butcher Crown Roadhouse',  img:'/photos/Food/US/ButcherCrown/main.jpg',  
    intro: `Serving authentic BBQ from a wood smoker, Butcher Crown Roadhouse is a must try with the innovative menu serving Brisket Dip Tacos, Pulled Pork Nachos, and amazing sides like their elote covered in Vaquero sauce, chile-lime dust, grated cotija.`,
    generalInfo: 
      [
        {title: 'Hours', text: `Noon-'til-soldout', 7:15pm`},
        {title: 'Location', text: `1905 Bodega Ave, Petaluma, CA 94952`},
        {title: 'Food', text: `Authentic  BBQ, Burgers, and Tacos`},
        {title: 'Parking', text: `Small Lot on the righ side, and two spots on the left side`},
      ],
    webLinks: [{title: 'Website', text: `butchercrown.com`, url: `https://www.butchercrown.com/`},],
    elements: 
    [
        {title: 'BBQ', img: '/photos/Food/US/ButcherCrown/image_1.jpg', paragraph: `Anyone living in Northern California knows that finding great BBQ is a difficult task, which makes Butcher Crown Roadhouse a treasure to the region. Their BBQ is slow cooked in their wood smoker in limited batches to maintain a high quality. They have fall-off the bone ribs, brisket that melts like butter, and Louisiana Hot Links with an amazing kick. `},
        {title: 'Roadhouse Burger', img: '/photos/Food/US/ButcherCrown/image_2.jpg', paragraph: `This brisket and chuck blend burger was a phenomenal choice as it is now one of my new go-to places for a BBQ burger. The patty is cooked medium, with the right amount of BBQ sauce,with an American and Cheddar-Jack cheese combo, and topped with bacon. I added a Louisiana hot link to my burger and highly recommend you do so too, as it added a nice spicy and smoky flavor to the burger.`},
        {title: 'Tacos, Nachos, and More', img: '/photos/Food/US/ButcherCrown/image_3.jpg', paragraph: `Butcher Crown Roadhouse is the first BBQ restaurant that I've been to that features tacos, nachos, and sides like elote. I wish I could tell you how good they are, but unfortunately they have been sold out the two times I've been there. They are fan favorite and it's recommended you show up for lunch to make sure you're able to order some.`},
    ],
  },


  {
    name: 'Tamalpie Pizza',  img:'/photos/Food/US/Tamalpie/main.jpg',  
    intro: `known for their artisan pizza, tamalpie is the spot for those looking for combos that use fresh and organic ingredients. The crust is worth the try as they prepare it 48 hours in advance to let it rise.`,
    generalInfo: 
      [
        {title: 'Hours', text: `Mon-Thur: 5:00pm-9:00pm, Fri-Sun: 11:30am-9:00pm`},
        {title: 'Location', text: `477 Miller Ave Mill Valley, CA 94941`},
        {title: 'Food', text: `Pizza, Italian-American Fusion, Cocktails`},
        {title: 'Parking', text: `Street Parking`},
      ],
    webLinks: [{title: 'Website', text: `www.tamalpiepizza.com`, url: `https://www.tamalpiepizza.com/`},],
    elements: 
    [
        {title: 'Pizza', img: '/photos/Food/US/Tamalpie/image_1.jpg', paragraph: `Tamalpie has a great menu with pizzas that include ingredients such as: wild mushrooms, black forest ham, caramelized onions, and mixed cherry tomatoes to name a few. \n\nWith its proximity to Muir Woods and many other hiking trails, Tamalpie is a go-to choice for those who have worked up an appetite after a day outdoors activities. This is also a perfect spot when I'm trying to avoid heavy and overly greasy pizza`},
        {title: 'Summit Pizza', img: '/photos/Food/US/Tamalpie/image_2.jpg', paragraph: `We'gve ordered the Summit Pizza three times now, and it has been spectacular every time. The black forest ham, pepperoncini, and feta cheese give the pizza a great saltiness and balanced perfectly with the sweetness of pineapple pieces.`},
        // {title: '', img: '/photos/Food/US//image_3.jpg', paragraph: ``},
    ],
  },
  
]