export const countryGeneralInfo = 
[
  {
    index: 0, name: 'United States', language: 'English', Currency: 'US Dollar', img: '/photos/Regions/top/sonoma_county.jpg',
    visa: 'https://www.passportindex.org/comparebyDestination.php',
    costs: 'https://www.numbeo.com/cost-of-living/country_result.jsp?country=United+States',
    wikitravel: 'https://wikitravel.org/en/United_States_of_America',
    thoughts: 
    [
      `If you come from small country it might be difficult to comprehend the size of the United State, so take the distance between all the place you'd like to visit into consideration`,
      `Pack the appropriate type of clothes since the climate and geography of the United State can change by region`,
      `Be aware that culture, social norms, and accents can vary so greatly from city to city, state to state, and the different regions.`
    ],
  },
];


export const countryAttractions = 
[
  {index: 0, name: 'United States',
   attractions: 
    [
      {img: '/photos/Countries/UnitedStates/destinations.jpg', title: 'Destinations', desc: '', link: '/Destinations'},
      {img: '/photos/Countries/UnitedStates/food.jpg', title: 'Food', desc: '', link: '/Food'},
      {img: '/photos/Countries/UnitedStates/hikes.jpg', title: 'Hikes', desc: '', link: '/Hikes'},
      {img: '/photos/Countries/UnitedStates/stores.jpg', title: 'stores', desc: '', link: '/Stores'},
    ]},
  {}
];