import React from 'react';
import {Link} from 'react-router-dom';

// Data
import {hikingLinks} from './Data/hikeLinkData.js';


export const HikingLinks = () =>
{
  
  return (
    <div className="hiking-links-container">
      {hikingLinks.map((link, index) =>
      {
        return (
          <article className="hiking-link" key={index} >
            <img className='hiking-link-img' src={link.img} alt="" />
            <span className="hiking-link-title">{link.name}</span>
            <span className={`hiking-difficulty ${link.difficulty}-difficulty`}>{link.difficulty}</span>
            <p className="hiking-link-desc">{link.desc}</p>

            <Link className="hiking-link" to={{pathname: link.path, search: link.search}} state={`${index}`}>
              <button className='services-btn'>Explore</button >
            </Link>
          </article>
        )
      })}
    </div>
  )
}