import React, {useEffect} from 'react';

// Data
import {destinationData} from './Data/destinationData.js';

// Components
import {Navbar} from './../Navbar.js';
import {Footer} from './../Footer.js';
import {Sidebar} from './../Sidebar/Sidebar.js'


export const Destination = () =>
{
  const params = new URLSearchParams(window.location.search);
  const index = params.get('index');
  const data = destinationData[index];

  useEffect((() =>
  {
    const position = window.innerHeight;

    if(position !== 0)
    {
      window.scrollTo(0,0);
    }

  }),[]);


  


  return (
    <>
      <Navbar />

      <section className="destination-section">
        <div className="destination-img-div" style={{backgroundImage: `url(${data.mainImg})`}}>
          <span className="destination-img-title">{data.title}</span>
        </div>

        {console.log(data.mainImg)}
        <main className="destination-main">
          <p className="destination-intro-paragraph">{data.intro}</p>

          <div className="desination-topic-container">
            {
              data.elements.map((item, index) =>
              {
                return (
                  <div className="destination-topic" key={index}>
                    <span className="destination-topic-header">{item.title}</span>
                    {(item.img) ?<img src={item.img} alt="" className="destination-topic-img" />:<></>}
                    <p className="destination-topic-paragraph">{item.paragraph}</p>
                  </div> )
              })
            }
          </div>

        </main>

        <Sidebar id="destination-sidebar">Test</Sidebar>
      </section>

      <Footer />
    </>
  )
}