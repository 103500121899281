import React from 'react';

// Componenets
import { Attractions } from './Attractions';


export const CountryAttractions = () =>
{

  return (
    <section className="services">
      <div className="services-center" id='destinations-article'>
        <Attractions></Attractions>
      </div>
    </section>
  )
}