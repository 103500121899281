// Hiking Links Object Data Format
// name: '', path: 'tennesseevalleybeach',   img:'', 
// difficulty: '', search: '?index=0',
// desc: ``,
// tags: `california marin county united states beach cliffs`,
// intro: ``


export const mainData = {mainImgLarge: `/photos/Hikes/main_img_large.jpg`,
                         mainImgMedium: `/photos/Hikes/main_img_medium.jpg`,
                         mainImgSmall: `/photos/Hikes/main_img_small.jpg`,}



// Last index used *** 12 ***
export const hikingLinks = 
[
  {
    name: 'Chimney Rock', path: 'chimneyrock', img: '/photos/Hikes/ChimneyRock/thumbnail.jpg',
    difficulty: 'easy', search: '?index=12',
    desc: 'An easy trail leading to stunning views of the Pacific',
    tags: `beach marin county point reyes national seashore pacific ocean`
  },
  
  {
    name: 'Armstrong Redwoods', path: `armstrongredwoods`, img:'/photos/Hikes/ArmstrongForest/thumbnail.jpg', 
    difficulty: 'easy', search: '?index=7',
    desc: `A beautiful Redwood forest that's a great alternative to Muir Woods`,
    tags: `california sonoma county united states `,
  },

  {
    name: 'Sonoma Coast', path: 'sonomacoast',  img:'/photos/Hikes/SonomaCoast/thumbnail.jpg', 
    difficulty: 'easy', search: '?index=8',
    desc: `A beautiful trail that'll take you along the Sonma Coast`,
    tags: `california sonoma county united states beaches cliffs`,
  },

  {
    name: 'Limantour Beach', path: `limantourbeach`, img:'/photos/Hikes/Limantour/thumbnail.jpg', 
    difficulty: 'easy', search: '?index=11',
    desc: `An amazing beach with unique peninsula formation`,
    tags: ``,
  },

  {
    name: 'Wolf Ridge Trail', path: 'wolfridgetrail',  img:'/photos/Hikes/WolfRidge/thumbnail.jpg', 
    difficulty: 'easy', search: '?index=4',
    desc: `A hike to an old Cold War Radar Station`,
    tags: `california marin county united states beach cliffs marin headlands army bunkers`,
  },

  {
    name: 'Alamere Falls', path: 'alamerefalls',  img:'/photos/Hikes/AlamereFalls/thumbnail.jpg', 
    difficulty: 'moderate', search: '?index=9',
    desc: `A beautiful "Tidefall" that flows into the ocean in Point Reyes`,
    tags: `waterfall tidefall california point reyes national park beach marin county`,
  },

  {
    name: 'Trojan Point', path: `trojanpoint`, img:'/photos/Hikes/TrojanPoint/thumbnail.jpg', 
    difficulty: 'easy', search: '?index=6',
    desc: `An easy hike with stunning views of S.F. Bay and Stinson Beach`,
    tags: `california marin county united states mount tamalpais`,
  },

  {
    name: 'Tennessee Valley trail', path: 'tennesseevalleytrail',  img:'/photos/Hikes/Tennessee/thumbnail.jpg', 
    difficulty: 'easy', search: '?index=0',
    desc: `Any easy hike that leads you to Tennesse Valley Beach`,
    tags: `california marin county united states beach cliffs`,
  },

  {
    name: 'Tomales Elk Reserve', path: `tomaleselkreserve`, img:'/photos/Hikes/TomalesElk/thumbnail.jpg', 
    difficulty: 'moderate', search: '?index=1',
    desc: `A peninsula at the northern end of Point Reyes, it is the home to Tule Elk`,
    tags: `california marin county united states beach cliffs tule elk tomales point reyes`,
  },

  {
    name: 'Five Brooks Trailhead', path: `fivebrookstrailhead`, img:'/photos/Hikes/FiveBrooks/thumbnail.jpg', 
    difficulty: 'hard', search: '?index=2',
    desc: `Starting at the beach it has access to some of the most beautiful cliffs`,
    tags: `california marin county united states beach cliffs`,
  },

  {
    name: 'Rodeo Beach', path: `rodeobeach`, img:'/photos/Hikes/RodeoBeach/main.jpg', 
    difficulty: 'moderate', search: '?index=3',
    desc: `The beach, coastal cliff, and old WWII bunkers this is a paradise for exploring`,
    tags: `california marin county united states beach cliffs army bunkers`,
  },

  {
    name: 'SCA Trail', path: `scatrail`, img:'/photos/Hikes/SCA_Trail/thumbnail.jpg', 
    difficulty: 'easy', search: '?index=5',
    desc: `A beautiful trail with the Golden Gate Bridge in the backdrop`,
    tags: `california marin county united states golden gate bridge marin headlands`,
  },

  {
    name: 'Dias Ridge', path: `diasridge`, img:'/photos/Hikes/Dias_Ridge/thumbnail.jpg', 
    difficulty: 'easy', search: '?index=10',
    desc: `A beautiful hike down the road from Muir Woods`,
    tags: ``,
  },
]

  