import React from 'react';
import {ServicesArticle} from './ServicesArticle.js';
import {homePageServices} from './../Data/data.js';


export const Services = () =>
{

  return (
    <section className="services">
      {/* <h2 className='services-title'>What We've Found</h2> */}
      <div className="services-center" id='-destinations-article'>
        {homePageServices.map( (item, index) =>
          {
            return <ServicesArticle key={index} img={item.img} mobileImg={item.mobileImg} title={item.title} desc={item.desc}
                                    link={item.link}/>
          })}


          <article className="services-article">
            <img src='/photos/HomePage/Services/substack_logo.png' className='services-article-img' alt="" />
            <div className="services-article-info">
              <h4>Substack Articles</h4>
              <p>
                A catalog of articles I've written for my Substack
              </p>

              <button className="services-btn">
                <a href='https://pablocortez.substack.com'>Explore</a>
              </button>
            </div>
          </article>
      </div>
    </section>
  )
}