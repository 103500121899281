import React, {useEffect} from 'react';


// Data
import {restaurantData} from './Data/restaurantData';

// Components
import {Navbar} from './../Navbar.js';
import {Footer} from './../Footer.js';
import {Sidebar} from './../Sidebar/Sidebar.js';


export const Restaurant = () =>
{
  const params = new URLSearchParams(window.location.search);
  const index = params.get('index');
  const data = restaurantData[index];

  useEffect((() =>
  {
    const position = window.innerHeight;

    if(position !== 0)
    {
      window.scrollTo(0,0);
    }

  }),[]);


  return (
    <>
      <Navbar/>

      <header className='restaurant-header' style={{backgroundImage: `url(${data.img})`}}>
        <div className="restaurant-header-modal">
          <span className="restaurant-header-title">{data.name}</span>
        </div>
      </header>

      <section className="restaurant-main-section">
        <main className="restaurant-main">
          <ul className="general-info">
            {data.generalInfo.map((info, index) =>
            {
              return (
                <li className='gen-info-li' key={index}><span className="blue-text">{info.title}</span>: {info.text}</li>
              )
            })}

            
            {data.webLinks.length !== 0? data.webLinks.map((info, index) =>
            {
              return (
                <li className='gen-info-li' key={index}>
                  <span className="blue-text">{info.title}</span>: <a href={info.url} rel="noreferrer" target='_blank'>{info.text}</a>
                </li>
              )
            }): <></>}
          </ul>

          <p className="restaurant-intro-paragraph">{data.intro}</p>

          <div className="topic-container">
            {data.elements.map((item, index) =>
            {
              return (
                <div className="destination-topic" key={index}>
                  <span className="destination-topic-header">{item.title}</span>
                  {item.img?<img src={item.img} alt="" className="destination-topic-img" />:<></>}
                  <p className="destination-topic-paragraph">{item.paragraph}</p>
                </div>
              )
            })}
          </div>
           
        </main>

        <Sidebar id='restaurant-sidebar'/>
      </section>

      <Footer/>
    </>
  )
}