import React from 'react';
import { Navbar } from '../Navbar';


export const Stores = () =>
{

  return (
    <>
      <Navbar/>
      <section className="stores-section">
        <main className="store-page-main">
          <h1>Stores Page</h1>
        </main>
      </section>
    </>
  )
}