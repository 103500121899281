import React from 'react';
import { useGlobalContext } from './AppContext';
import { Link } from 'react-router-dom';
import {FaBars} from 'react-icons/fa'


export const Navbar = () =>
{
  const globalVals = useGlobalContext();
  const showLinks = globalVals.showLinks;
  const setShowLinks = globalVals.setShowLinks;
  const dropdownRef = globalVals.dropdownRef;


  const displayDropdown = () =>
  {
    if(!showLinks)
    {
      const PADDING = 5;
      const height = dropdownRef.current.firstChild.getBoundingClientRect().height;
      dropdownRef.current.style.height = `${height + (2*PADDING)}px`;
      dropdownRef.current.style.padding = '5px 0px';
    }

    else
    {
      dropdownRef.current.style.height = '0px';
      dropdownRef.current.style.padding = '0px';
    }

    setShowLinks(!showLinks);
  }


  return (
    <nav className="navbar">
      <Link className='' to='/'>
        <div className="navbar-logo">
          <p>Cortez <br/> Travel <br/> Guide</p>
        </div>
      </Link>
      

      <div className="navbar-center">
        <ul className='navbar-links'>
          <Link className='navbar-link' to='/'>Home</Link>
          <Link className='navbar-link' to='/Destinations'>Dest.</Link>
          <Link className="navbar-link" to='/Food'>Food</Link>
          <Link className="navbar-link" to='/Hikes'>Hikes</Link>
          <Link className="navbar-link" to='/BudgetCalculator'>Budget</Link>
          <Link className="navbar-link" to='/About'>About</Link>
        </ul>

        <button className='navbar-toggle-btn' onClick={displayDropdown}>
          <FaBars/>
        </button>

        <div className="navbar-dropdown-links " ref={dropdownRef}>
          <ul className='dropdown-links'>
            <Link className='navbar-link' to='/' onClick={displayDropdown}>Home</Link>
            <Link className='navbar-link' to='/Destinations' onClick={displayDropdown}>Dest.</Link>
            <Link className="navbar-link" to='/Food' onClick={displayDropdown}>Food</Link>
            <Link className="navbar-link" to='/Hikes'>Hikes</Link>
            <Link className="navbar-link" to='/BudgetCalculator' onClick={displayDropdown}>Budget</Link>
            <Link className="navbar-link" to='/About' onClick={displayDropdown}>About</Link>
          </ul>
        </div>

      </div>
    </nav>
  )
}