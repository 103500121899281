

// This will be used for the Regions page to populate each <Link/> tag
export const regionsLinksData = [
  {name: `United States`, index: 0, img: `/photos/Countries/UnitedStates/main.jpg`,  path: `UnitedStates`, tags: `california marin county`},
  {name: `Sonoma County`, index: 1, img: `/photos/Regions/top/sonoma_county.jpg`, path: `SonomaCounty`, tags: ` california sonoma county`},
];


// This will be used for the individaul Regions Area page
export const areaData = 
[
  { 
    name: `United States`, index: 0, img: `/photos/Countries/UnitedStates/main.jpg`,  mobileImg: `/photos/Regions/top/marin_county.jpg`, path: `MarinCounty`, tags: `california marin county`
  },

  { 
    name: `Sonoma County`, index: 1, img: `/photos/Regions/top/sonoma_county.jpg`,  path: `SonomaCounty`, tags: `california sonoma county`
  },
]
