import React, {useRef, useEffect, useState} from 'react';


export const MainSection = () =>
{
  // const imgRef = useRef(null);
  const [path, setPath] = useState('/photos/img_');


  useEffect(() =>
  {
    if(window.innerWidth < 601)
    {
      setPath('/photos/img_mobile_');
    }

    

    window.addEventListener('resize', ()  =>
    {
      if(window.innerWidth < 601)
      {
        setPath('/photos/img_mobile_');
      }

      else if(path === '/photos/img_')
      {
        return;
      }

      else
      {
        setPath('/photos/img_');
      }

    }); 
  }, [path]);


  return (
    <main className="homepage-main">
      {/* <img  className='homepage-img' src={`${path}0.jpg`} ref={imgRef} alt="" /> */}

      <section className="homepage-main-center">
        <h4 className='homepage-main-title'>
          Come as we Explore the Bay Area and California
        </h4>
      </section>
    </main>
  )
}