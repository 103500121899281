export const homePageServices = [{img: '/photos/HomePage/Services/destinations.jpg', 
                                  mobileImg: '/photos/HomePage/Services/img_mobile_3.jpg', 
                                  title: 'Destinations', desc: `A list of some of our favorite travel destinations`,
                                  link: '/Destinations'},
                                 {img: '/photos/HomePage/Services/img_3.jpg', 
                                  mobileImg: '/photos/HomePage/Services/img_mobile_3.jpg', 
                                  title: 'Food', desc: `Checkout some of our favorite places to eat`,
                                  link: '/Food'},
                                 {img: '/photos/HomePage/Services/hikes.jpg', 
                                  mobileImg: '/photos/HomePage/Services/img_mobile_3.jpg', 
                                  title: 'Hikes', desc: `For those wanting to explore nature, we have a list of our
                                                         favorite hikes`,
                                  link: '/Hikes'},
                                //  {img: '/photos/HomePage/Services/img_3.jpg', 
                                //   mobileImg: '/photos/HomePage/Services/img_mobile_3.jpg', 
                                //   title: 'Stores', desc: `Come explore some of our favorite shops.`,
                                //   link: '/Stores'},
                              ]