import React from 'react';
import {regionsLinksData} from '../DataFolder/data.js';
import { Link } from 'react-router-dom';


export const RegionLink = () =>
{

  return (
   regionsLinksData.map((item, index) =>
   {
    return (
      <Link to={item.path} key={index} state={`${index}`}>
        <article className="region-article">
          <img className="region-article-img" src={item.img} alt="" />
          <span className='region-article-title'>{item.name}</span>
        </article>
      </Link>
    )
   })
  )
}