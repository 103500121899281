import React from 'react';


export const Footer = () =>
{
  const date = (new Date()).getFullYear();

  return (
    <footer className='footer'>
      <span className='footer-text'>&#169; {date} Cortez Travel Guide</span>
    </footer>
  )
}