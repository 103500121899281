import React from 'react';
import {Link, useLocation} from 'react-router-dom';

// Data 
import {countryAttractions} from './DataFolder/countriesData.js';

export const Attractions = () =>
{
  const index = useLocation().state;
  const data = countryAttractions[index].attractions;

  return (
    data.map((item, index) =>
    {
      {console.log(item);}
      return (
        
        <article className="services-article" key={index}>
          <img src={item.img} className='services-article-img' alt="" />
          <div className="services-article-info">
            <h4>{item.title}</h4>
            <p>
              {item.desc}
            </p>

            <button className="services-btn">
              <Link to={item.link}>Explore</Link>
            </button>
          </div>
        </article>
      )
    })
  )
}