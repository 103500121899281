import React, {useContext, useState, useRef} from 'react';

const AppContext = React.createContext();

export const AppContextProvider = ({children}) =>
{
  const [showLinks, setShowLinks] = useState(true);
  const dropdownRef = useRef(null);

  return (
    <AppContext.Provider value={{showLinks, setShowLinks, dropdownRef}}>
      {children}
    </AppContext.Provider>
  )
}


export const useGlobalContext = () =>
{
  return useContext(AppContext);
}

