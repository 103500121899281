// {
//   title: '', img: '', tags: ``,
//   desc: ``, 
//   path: '', search: ,
// },



export const destinationLinks = 
[
  {
    title: 'Sausalito', img: '/photos/Destinations/Articles/Sausalito.jpg',  tags: `sausalito california marin county towns bay area`,
    desc: `A small family friendly town in Northern California with amazing views of the Bay and San Francisco`, 
    path: 'sausalito', search: '?index=0',
  },

  {
    title: 'Mill Valley', img: '/photos/Destinations/Mill_Valley/thumbnail.jpg', tags: `mill valley california marin county towns bay area`,
    desc: `Nestled amongst the Redwoods, Mill Valley is an amazing starting point to your adventure in Northern California`, 
    path: 'millvalley', search: '?index=1',
  },

  {
    title: 'Mavericks Beach', img: '/photos/Destinations/Mavericks_Beach/thumbnail.jpg', tags: `mavericks beach halfmoon bay california bay area`,
    desc: `Home to some the biggest waves in the world`, 
    path: 'mavericksbeach', search: '?index=6',
  },

  {
    title: 'Tiburon', img: '/photos/Destinations/Tiburon/main.jpg', tags: `tiburon california marin county towns bay area`,
    desc: `A charming secluded town that makes for a perfect getaway from the city`, 
    path: 'tiburon', search: '?index=2',
  },

  {
    title: 'Golde Gate Bridge', img: '/photos/Destinations/GGBridge/thumbnail.jpg', tags: `golden gate bridge california san francisco bay area`,
    desc: `Seen as one of the wonders of the modern world, it has come to symbolize the Bay Area`, 
    path: 'goldengatebridge', search: '?index=3',
  },

  {
    title: 'Santa Rosa', img: '/photos/Destinations/SantaRosa/thumbnail.jpg', tags: `Santa Rosa Sonoma County California bay area`,
    desc: `An hour North of San Francisco, it's a beautiful city in the heart of wine country`, 
    path: 'santarosa', search: '?index=4',
  },

  {
    title: 'Montara Beach', img: '/photos/Destinations/Montara_Beach/thumbnail.jpg', tags: `montara beach moss cliffs california pacifica bay area`,
    desc: `North of Pacifica, Montara Beach has beathtaking bluffs to explore`, 
    path: 'montarabeach', search: '?index=5',
  },

  {
    title: 'Point Bonita Lighthouse', img: '/photos/Destinations/BonitaLighthouse/thumbnail.jpg', tags: `point bonita lighthouse marin headlands california bay area`,
    desc: `A beautiful lighthouse found in the Marin Headlands`, 
    path: 'montarabeach', search: '?index=7',
  },

]// The last index was '?index=7'

