import React, {useState, useRef, useEffect} from 'react';
import {isValidNumber} from './functions.js';

//Components
import {Navbar} from './../Navbar.js';
import {Footer} from './../Footer.js';

export const BudgetCalculator = () =>
{
  const errorRef = useRef(null);

  // Total Cost
  const [total, setTotal] = useState(0);

  // Cost of Flights
  const [flight, setFlight] = useState(0);
  const [numFlights, setNumFlights] =  useState(0);
  const [baggage, setBaggage] = useState(0);

  // Cost of Food
  const [food, setFood] = useState(0);
  const [foodDays, setFoodDays] = useState(0);
  const [numFood, setNumFood] = useState(0);
  
  // Cost of Gear
  const [gear, setGear] = useState(0);
  const [numGear, setNumGear] = useState(0);

  // Cost of Lodging
  const [lodging, setLodging] = useState(0);
  const [lodgingDays, setLodgingDays] = useState(0);

  // Cost of Insurance
  const [insurance, setInsurance] = useState(0);
  const [numInsurance, setNumInsurance] = useState(0);

  // Cost of transportation
  const [transport, setTransport] = useState(0);
  const [numTransport, setNumTransport] = useState(0);
  const [transportDays, setTransportDays] = useState(0);

  // Cost of Visas
  const [visa, setVisa] = useState(0);
  const [numVisa, setNumVisa] = useState(0);

  // Misc. costs
  const [misc, setMisc] = useState(0);


  // Breakdown Figures
  const [numMonths, setNumMonths] = useState(6);
  const [monthlySaving, setMonthlySaving] = useState(0);
  const [monthlyBudget, setMonthlyBudget] = useState(350);
  const [monthlyCost, setMonthlyCost] = useState(0);
  

  const totalCost = () =>
  {
    let isError = 0;

    // Validating data for cost of flights
    isValidNumber(flight, 'Invalid number for Flights', errorRef)? isError += 1 : setFlight(0);
    isValidNumber(baggage, 'Invalid number for Baggage', errorRef)? isError += 1 : setBaggage(0);
    isValidNumber(numFlights, 'Invalid number for of People', errorRef)? isError += 1: setNumFlights(0);

    // Validating data for cost of food
    isValidNumber(food, 'Invalid number for Food', errorRef)? isError += 1: setFood(0);
    isValidNumber(foodDays, 'Invalid number for of Days', errorRef)? isError += 1: setFoodDays(0);
    isValidNumber(numFood, 'Invalid number for of People', errorRef)? isError += 1: setNumFood(0);

    // Validating data for cost of gear
    isValidNumber(gear, 'Invalid number for Gear', errorRef)? isError += 1: setGear(0);
    isValidNumber(numGear, 'Invalid number for of People', errorRef)? isError += 1: setNumGear(0);

    // Validating data for cost of lodging
    isValidNumber(lodging, 'Invalid number for Lodging', errorRef)? isError += 1: setLodging(0);
    isValidNumber(lodgingDays, 'Invalid number for of Days', errorRef)? isError += 1: setLodgingDays(0);

    // Validating data for cost of visa
    isValidNumber(visa, 'Invalid number for visa', errorRef)? isError += 1: setVisa(0);
    isValidNumber(numVisa, 'Invalid number for of Days', errorRef)? isError += 1: setNumVisa(0);

    // Validating data for cost of transport
    isValidNumber(transport, 'Invalid number for transport', errorRef)? isError += 1: setTransport(0);
    isValidNumber(transportDays, 'Invalid number for of Days', errorRef)? isError += 1: setTransportDays(0);
    isValidNumber(numTransport, 'Invalid number for of People', errorRef)? isError += 1:  setNumTransport(0);
    
    // Validating data for cost of insurance
    isValidNumber(insurance, 'Invalid number for insurance', errorRef)? isError += 1: setInsurance(0);
    isValidNumber(numInsurance, 'Invalid number for of Days', errorRef)? isError += 1: setNumInsurance(0);

    // Validating data for cost of misc.
    isValidNumber(misc, 'Invalid number for misc', errorRef)? isError += 1: setMisc(0);

    if(isError !== 18)  
    {
      setTotal(total); 
      return;
    }

    setTotal( ((parseInt(flight) + parseInt(baggage)) * parseInt(numFlights)) + 
              (parseInt(food) * parseInt(numFood) * parseInt(foodDays)) + 
              (parseInt(gear) * parseInt(numGear)) + 
              (parseInt(lodging) * parseInt(lodgingDays)) + 
              (parseInt(insurance) * parseInt(numInsurance)) + 
              (parseInt(transport) * parseInt(numTransport) * parseInt(transportDays))  + 
              (parseInt(visa) * parseInt(numVisa))+ 
               parseInt(misc));
  }


  const calcMonthlySaving = () =>
  {
    let isError = 0;

    isValidNumber(numMonths, 'Invalid number for Flights', errorRef)? isError += 1 : setNumMonths(0);

    if(isError < 1)
    {
      console.log('log 1');
      setMonthlySaving(monthlySaving);
      return;
    }

    if(numMonths === 0)
    {
      console.log('log 2');
      setMonthlySaving(0);
      return;
    }

    setMonthlySaving(Math.ceil(total/numMonths));
  }


  const calcNumMonthstoSave = () =>
  {
    let isError = 0;

    isValidNumber(monthlyBudget, 'Invalid number for Flights', errorRef)? isError += 1 : setMonthlyBudget(0);

    if(isError < 1)
    {
      console.log('log-1');

      setMonthlyCost(0);
      return;
    }

    if(monthlyBudget === 0)
    {
      console.log('log-2');
      setMonthlyCost(0);
      return;
    }

    setMonthlyCost(Math.ceil(total/monthlyBudget));

  }


   useEffect( () => 
    {
      totalCost();
      calcMonthlySaving();
      calcNumMonthstoSave();
    }, 
    [total]);

  return (
    <>
      <Navbar />
      <section className="budget-calc">
        <h1 className='budget-calc-title'>Budget Calculator</h1>

        <main className="budget-calc-main">
          <form action="" className="budget-calc-form" onSubmit={e => { e.preventDefault(); totalCost()}}>

            {/* Flights */}
            <span className="budget-field-title">Flights</span>
            <div className="budget-field">
              <label htmlFor="flight" className='budget-form-label'>Flight(s)</label>
              <input type="number"  className="budget-form-input" name='flight' value={flight} placeholder='0' min={0}
                   onChange={(e) => setFlight((e.target.value))} pattern=''/>

              <label htmlFor="baggage" className='budget-form-label'>Baggage Fees</label>
              <input type="number"  className="budget-form-input" name='baggage' value={baggage} placeholder='0' min={0}
                    onChange={(e) => setBaggage((e.target.value))} pattern='([0-9]|[1-9][0-9]{1,6}|10000000)'/>

              <label htmlFor="numFlights" className="budget-form-label">Number of People</label>
              <input type="number" className='budget-form-input' namge='numFlights' value={numFlights} placeholder={0} min={0}
                     onChange={(e) => setNumFlights((e.target.value))}  pattern='([0-9]|[1-9][0-9]{1,6}|10000000)'/>
            </div>
            
            {/* Food */}
            <span className="budget-field-title">Food</span>
            <div className="budget-field">
              <label htmlFor="food" className='budget-form-label'>Food</label>
              <input type="number"  className="budget-form-input" name='food' value={food} placeholder='0' min={0}
                    onChange={(e) => setFood(e.target.value)} pattern='([0-9]|[1-9][0-9]{1,6}|10000000)'/>

              <label htmlFor="foodDays" className='budget-form-label'>Number of Days</label>
              <input type="number"  className="budget-form-input" name='foodDays' value={foodDays} placeholder='0' min={0}
                    onChange={(e) => setFoodDays(e.target.value)} pattern='([0-9]|[1-9][0-9]{1,6}|10000000)'/>

              <label htmlFor="numFood" className='budget-form-label'>Number of People</label>
              <input type="number"  className="budget-form-input" name='numFood' value={numFood} placeholder='0' min={0}
                    onChange={(e) => setNumFood(e.target.value)} pattern='([0-9]|[1-9][0-9]{1,6}|10000000)'/>
            </div>
      
          
            {/* Gear */}
            <span className="budget-field-title">Gear</span>
            <div className="budget-field">
              <label htmlFor="gear" className='budget-form-label'>Gear</label>
              <input type="number"  className="budget-form-input" name='gear' value={gear} placeholder='0' min={0}
                    onChange={(e) => setGear(e.target.value)} pattern='([0-9]|[1-9][0-9]{1,6}|10000000)'/>

              <label htmlFor="numGear" className='budget-form-label'>Number of People</label>
              <input type="number"  className="budget-form-input" name='numGear' value={numGear} placeholder='0' min={0}
                    onChange={(e) => setNumGear(e.target.value)} pattern='([0-9]|[1-9][0-9]{1,6}|10000000)'/>
            </div>
            

            {/* Lodging */}
            <span className="budget-field-title">Lodging</span>
            <div className="budget-field">
              <label htmlFor="lodging" className='budget-form-label'>Lodging</label>
              <input type="number"  className="budget-form-input" name='lodging' value={lodging} placeholder='0' min={0}
                    onChange={(e) => setLodging(e.target.value)} pattern='([0-9]|[1-9][0-9]{1,6}|10000000)'/>
              
              <label htmlFor="lodgingDays" className='budget-form-label'>Number of Nights</label>
              <input type="number"  className="budget-form-input" name='lodgingDays' value={lodgingDays} placeholder='0' min={0}
                    onChange={(e) => setLodgingDays(e.target.value)} pattern='([0-9]|[1-9][0-9]{1,6}|10000000)'/>
            </div>
            

            {/* Visas */}
            <span className="budget-field-title">Visa(s)</span>
            <div className="budget-field">
              <label htmlFor="visa" className='budget-form-label'>Visa(s)</label>
              <input type="number"  className="budget-form-input" name='visa' value={visa} placeholder='0' min={0}
                    onChange={(e) => setVisa(e.target.value)} pattern='([0-9]|[1-9][0-9]{1,6}|10000000)'/>

              <label htmlFor="visa" className='budget-form-label'>Number of Visas</label>
              <input type="number"  className="budget-form-input" name='numVisas' value={numVisa} placeholder='0' min={0}
                    onChange={(e) => setNumVisa(e.target.value)} pattern='([0-9]|[1-9][0-9]{1,6}|10000000)'/>
            </div>
            

            {/* Transportation */}
            <span className="budget-field-title">Transportation</span>
            <div className="budget-field">
              <label htmlFor="transport" className='budget-form-label'>Transportation</label> 
              <input type="number"  className="budget-form-input" name='transport' value={transport} placeholder='0' min={0}
                    onChange={(e) => setTransport(e.target.value)} pattern='([0-9]|[1-9][0-9]{1,6}|10000000)'/>

              <label htmlFor="numTransport" className='budget-form-label'>Number of People</label> 
              <input type="number"  className="budget-form-input" name='transport' value={numTransport} placeholder='0' min={0}
                    onChange={(e) => setNumTransport(e.target.value)} pattern='([0-9]|[1-9][0-9]{1,6}|10000000)'/>

              <label htmlFor="transportDays" className='budget-form-label'>Number of Days</label> 
              <input type="number"  className="budget-form-input" name='transportDays' value={transportDays} placeholder='0' min={0}
                    onChange={(e) => setTransportDays((e.target.value))} pattern='([0-9]|[1-9][0-9]{1,6}|10000000)'/>
            </div>


            {/* Insurance */}
            <span className="budget-field-title">Insurance</span>
            <div className="budget-field">
              <label htmlFor="insurance" className='budget-form-label'>Travelers Insurance</label>
              <input type="number"  className="budget-form-input" name='insurance' value={insurance} placeholder='0' min={0} 
                   onChange={(e) => setInsurance(e.target.value)} pattern='([0-9]|[1-9][0-9]{1,6}|10000000)'/>

              <label htmlFor="numInsurance" className='budget-form-label'>Number of People</label>
              <input type="number"  className="budget-form-input" name='numInsurance' value={numInsurance} placeholder='0' min={0}
                   onChange={(e) => setNumInsurance(e.target.value)} pattern='([0-9]|[1-9][0-9]{1,6}|10000000)'/>
            </div>


            {/* Misc. */}
            <span className="budget-field-title">Misc.</span>
            <div className="budget-field">
              <label htmlFor="misc" className='budget-form-label'>Misc.</label>
              <input type="number"  className="budget-form-input" name='misc' value={misc} placeholder='0' min={0}
                    onChange={(e) => setMisc(e.target.value)}/>
            </div>
            

            {/* <label htmlFor="" className='budget-form-label'></label>
            <input type="number"  className="budget-form-input" name='' value={} onChange={(e) => set(e.target.value)}/> */}

            <div className=""></div>
            <button type='submit' className="budget-form-btn">Calculate</button>

          </form>

          <div className="breakdown-sheet">
            <h2>Breakdown</h2>

            {/* This will be were the budget is broken down for the user */}
            <div className="budget-breakdown">
              <span className="budget-breakdown-title">Total cost: </span>
              <span className="budget-breakdown-number">${total}</span>
            </div>

            <div className="budget-breakdown">
              <span className="budget-breakdown-title">How Much to Save</span>
              <form action="" className='months-form' onSubmit={(e) => {e.preventDefault(); calcMonthlySaving()}}>
                <label htmlFor="months">Number of Months</label>
                <input type="number" className='budget-form-input' value={numMonths} placeholder={0} min={0}
                       onChange={(e) => setNumMonths(e.target.value)} />
                <button className="months-form-btn">Calculate</button>
              </form>

              <span className='line-break-span'></span>

              <span className="budget-breakdown-number">${monthlySaving}</span>
            </div>

            <div className="budget-breakdown">
              <span className="budget-breakdown-title">How Long to Save</span>
              <form action="" className='months-form' onSubmit={(e) => {e.preventDefault(); calcNumMonthstoSave()}}>
                <label htmlFor="months" >Monthly Budget</label>
                <input type="number" className='budget-form-input' value={monthlyBudget} placeholder={0} min={0}
                       onChange={(e) => setMonthlyBudget(e.target.value)} />
                <button className="months-form-btn">Calculate</button>
              </form>

              <span className='line-break-span'></span>

              <span className="budget-breakdown-number">{monthlyCost} Months</span>
            </div>

          </div>
        </main>
      </section>

      {/* This will display an error message if user has an empty input field */}
      <div className="error-message-div display-none" ref={errorRef}>Test</div>

      <Footer />
    </>
  )
}